import { Button, Popover, Stack } from "@mui/material";
import { DashboardExpensesFilters } from "@store/dashboardExpenses/dashboardExpenses.models";
import { useTranslation } from "react-i18next";
import { useAnchor } from "src/hooks/use-anchor.function";
import FilterListIcon from "@mui/icons-material/FilterList";
import { Dispatch, SetStateAction } from "react";
import { DatePicker } from "@mui/x-date-pickers";

export interface DashboardExpensesFiltersModalProps {
  filters: DashboardExpensesFilters;
  setFilters: Dispatch<SetStateAction<DashboardExpensesFilters>>;
}

const DashboardExpensesFiltersModal = (props: DashboardExpensesFiltersModalProps) => {
  const { filters, setFilters } = props;
  const { t } = useTranslation(undefined, { keyPrefix: "dashboardExpensesScreen.filters" });
  const { anchorEl, handleClick, handleClose } = useAnchor();

  return (
    <>
      <Button startIcon={<FilterListIcon />} onClick={handleClick} variant="contained">
        {t("title")}
      </Button>
      <Popover
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        style={{ marginTop: "10px" }}
      >
        <Stack p="40px 20px" spacing={2}>
          <DatePicker
            label={t("from")}
            value={filters.from}
            onChange={(newValue) => {
              if (newValue) setFilters({ ...filters, from: newValue });
            }}
          />
          <DatePicker
            format="dd/MM/yyyy"
            label={t("to")}
            value={filters.to}
            onChange={(newValue) => {
              if (newValue) setFilters({ ...filters, to: newValue });
            }}
          />
        </Stack>
      </Popover>
    </>
  );
};

export default DashboardExpensesFiltersModal;
