import { Colors } from "src/constants/colors.constants";
import { Button, Stack, Typography } from "@mui/material";
import Logo from "src/assets/images/img_logo.svg";
import * as Yup from "yup";
import CustomRegister from "src/utils/custom-register.function";
import { InferType } from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import TextFieldForm from "src/components/form/TextFieldForm.component";
import { useTranslate } from "src/utils/i18n.utils";
import { useEffect, useState } from "react";
import { companiesServices } from "src/store/companies/companies.services";
import { useObservable } from "@ngneat/react-rxjs";
import { companiesQuery } from "src/store/companies/companies.query";
import { SelectFieldComponent } from "src/components/form/select-field.component";
import { APIRoutes } from "src/api/axios.api";
import APIAxios from "src/api/axios.api";
import { enqueueSnackbar } from "notistack";
import I18nUtils from "src/utils/i18n.utils";

interface PageLayoutProps {
  children: React.ReactNode;
  title: string;
  subtitle: string;
}
const PageLayout = ({ children, title, subtitle }: PageLayoutProps) => {
  return (
    <Stack alignItems="center" gap={2} height="100%" width={{ xs: "100%", md: "70%" }} paddingTop={6}>
      <img alt="logo" src={Logo} />
      <Stack width="100%" alignItems="center" gap={2}>
        <Typography fontSize="30px" fontWeight="800">
          {title}
        </Typography>
        <Typography fontSize="18px" fontWeight="400" color={Colors.secondaryText}>
          {subtitle}
        </Typography>
      </Stack>
      {children}
    </Stack>
  );
};
const { t } = I18nUtils;

const phoneRegex = /^\+?\d{10,15}$/;
const RegisterDataSchema = Yup.object({
  firstName: Yup.string().required(t("errors.required")),
  lastName: Yup.string().required(t("errors.required")),
  phoneNumber: Yup.string().required(t("errors.required")).matches(phoneRegex, t("errors.invalidPhoneNumber")),
  email: Yup.string().email().required(t("errors.required")),
  companyId: Yup.string().required(t("errors.required")),
});

export type RegisterData = InferType<typeof RegisterDataSchema>;

const Register = () => {
  const { t } = useTranslate("register");
  const [{ companies }] = useObservable(companiesQuery.companies$);
  const [success, setSuccess] = useState(false);
  const [timer, setTimer] = useState(30);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  useEffect(() => {
    const subscription = companiesServices.getCompaniesLight({ page: 1, orderBy: "name" }).subscribe();
    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    if (success) {
      const countdown = setInterval(() => {
        setTimer((prev) => {
          if (prev <= 1) {
            clearInterval(countdown);
            setIsButtonDisabled(false);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);

      return () => clearInterval(countdown);
    }
  }, [success]);

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<RegisterData>({
    resolver: yupResolver(RegisterDataSchema),
    mode: "all",
  });
  const customRegister = CustomRegister(register, errors);

  const resentMail = async () => {
    try {
      await APIAxios({ ...APIRoutes.POSTResendActivationMail(getValues("email")) });
      setTimer(30);
      setIsButtonDisabled(true);

      const countdown = setInterval(() => {
        setTimer((prev) => {
          if (prev <= 1) {
            clearInterval(countdown);
            setIsButtonDisabled(false);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    } catch (error) {
      enqueueSnackbar(t("errors.resendMailFailed"), { variant: "error" });
    }
  };

  const handleRegister = async (data: RegisterData) => {
    try {
      await APIAxios({ ...APIRoutes.POSTRegisterEmployee(), data });
      setSuccess(true);
    } catch (error: any) {
      enqueueSnackbar(t(`errors.${error.response.data.message}`), { variant: "error" });
    }
  };

  if (success) {
    return (
      <PageLayout title={t("successTitle")} subtitle={t("successMessage")}>
        <Typography sx={{ marginBottom: "16px", color: Colors.secondaryText }}>{t("noMail")}</Typography>
        <Button variant="contained" color="primary" onClick={resentMail} disabled={isButtonDisabled}>
          {isButtonDisabled ? `${t("resendMail")} (${timer}s)` : t("resendMail")}
        </Button>
      </PageLayout>
    );
  }

  return (
    <PageLayout title={t("title")} subtitle={t("subtitle")}>
      <form onSubmit={handleSubmit(handleRegister)} style={{ paddingTop: "20px", width: "100%" }}>
        <Stack gap={5}>
          <Stack gap={2} width="100%">
            <TextFieldForm required {...customRegister("firstName")} label={t("firstname")} />
            <TextFieldForm required {...customRegister("lastName")} label={t("lastname")} />
            <TextFieldForm required {...customRegister("email")} label={t("emailPro")} />
            <TextFieldForm required {...customRegister("phoneNumber")} label={t("phone")} />
            <SelectFieldComponent
              sx={{ backgroundColor: "white" }}
              required
              label={t("company")}
              value={getValues("companyId")}
              error={!!errors.companyId}
              helperText={errors.companyId?.message}
              options={companies?.map((company) => ({ label: company.name, value: company.id }))}
              onChange={(value) => value && setValue("companyId", value, { shouldValidate: true })}
            />
          </Stack>
          <Stack width="100%" alignItems="center">
            <Button type="submit" variant="contained" color="primary">
              {t("register")}
            </Button>
          </Stack>
        </Stack>
      </form>
    </PageLayout>
  );
};

export default Register;
