import { SxProps } from "@mui/system";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  SelectProps,
  Theme,
  Typography,
} from "@mui/material";
import { OverridableStringUnion } from "@mui/types";
import { InputBasePropsSizeOverrides } from "@mui/material/InputBase/InputBase";
import { useTranslation } from "react-i18next";

interface MenuItemOption<T> {
  value: T | undefined;
  label: string;
}

interface SelectFieldProps<T> extends Omit<SelectProps<T>, "onChange" | "value"> {
  selectId?: string;
  selectLabel?: string | null;
  value?: T | T[];
  defaultValue?: T;
  onChange?: (value: T | undefined) => void;
  options?: MenuItemOption<T>[];
  sx?: SxProps<Theme>;
  size?: OverridableStringUnion<"small" | "medium", InputBasePropsSizeOverrides>;
  multiple?: boolean;
  disable?: boolean;
  required?: boolean;
  label?: string | null;
  helperText?: string | null;
}

export function SelectFieldComponent<T>(props: SelectFieldProps<T>) {
  const {
    selectId,
    required,
    selectLabel,
    defaultValue,
    value,
    onChange,
    options,
    disable,
    multiple,
    sx,
    size,
    label,
    helperText,
  } = props;

  const { t } = useTranslation();
  const handleChange = (event: SelectChangeEvent<T>) => onChange?.(event.target.value as T);

  return (
    <>
      {!!label && <InputLabel sx={{ fontWeight: 600 }}>{label}</InputLabel>}
      <FormControl fullWidth sx={{ ...sx, justifyContent: "center" }}>
        <Typography fontSize="14px" fontWeight="500" color="primary" position="absolute" ml="15px">
          {value && value !== "0" && (!Array.isArray(value) || (value as any[])?.length !== 0) ? "" : selectLabel}
        </Typography>
        <Select
          {...props}
          label={undefined}
          fullWidth
          defaultValue={defaultValue}
          multiple={multiple}
          id={selectId}
          labelId={`${selectId}-label`}
          size={size}
          disabled={disable}
          value={(value ?? (multiple ? [] : "")) as any}
          onChange={handleChange}
          MenuProps={{
            style: { zIndex: 35001 },
          }}
          sx={{ border: props.error ? "1px solid red" : "none" }}
        >
          {!required && !multiple && <MenuItem>{t("global.none")}</MenuItem>}
          {options?.map((option, i) => (
            <MenuItem key={i} value={option.value as any}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
        {!!helperText && <Typography color="error">{helperText}</Typography>}
      </FormControl>
    </>
  );
}
