import { sharedCardsStore } from "./sharedCards.store";
import { getSharedCardsDataSource } from "./sharedCards.requests";
import { select, setProps } from "@ngneat/elf";
import { SharedCard } from "./sharedCards.models";

export class SharedCardsQuery {
  store = sharedCardsStore;
  sharedCards$ = getSharedCardsDataSource.data$;
  currentCard$ = this.store.pipe(select((state) => state.currentCard));
  setCurrentCard = (card: SharedCard | null) => this.store.update(setProps({ currentCard: card }));
}

export const sharedCardsQuery = new SharedCardsQuery();
