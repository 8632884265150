import React, { useEffect, useState } from "react";
import { Accordion, AccordionSummary, AccordionDetails, Stack, Typography, Grid, Button } from "@mui/material";
import { Colors } from "../../../../constants/colors.constants";
import { Trans, useTranslation } from "react-i18next";
import { useObservable } from "@ngneat/react-rxjs";
import {
  transactionsQuery,
  searchTransactionEffects,
  transactionServices,
  TransactionLight,
  TransactionStatus,
  SwanStatusInfo,
  searchTransactionTotalEffects,
} from "../../../../store/transaction";
import { useEffectFn } from "@ngneat/effects-hooks";

import BasicSearchbar from "../../../../components/inputs/BasicSearchBar.component";
import Chevron from "../../../../assets/icons/icon_arrow_down_dark.svg";
import FilterCard from "../../../../components/card/FilterCard.component";
import Export from "../../../../assets/icons/icon_export_light.svg";
import TransactionDetail from "./TransactionDetail.modal";
import { enqueueSnackbar } from "notistack";
import ExportTransactions from "./ExportTransactions.modal";
import ClosePeriod from "./ClosePeriod.modal";
import { UserRole } from "../../../../store/users";
import { groupObjectsByDate } from "../../../../utils/getTransactionDate";
import PaymentCardComponent from "../../../../components/card/PaymentCard.component";
import { useResponsive } from "../../../../utils/useResponsive";
import { paymentService } from "../../../../store/payments/payments.services";
import { getAccountBalance } from "src/store/swan/swan.services";
import { useLocation } from "react-router-dom";
import { filterDuplicatePayments } from "src/utils/filterDuplicationPayments";
import { sessionQuery, sessionService } from "src/store/session";
import SharedCardSection from "./SharedCardSection";

interface TransactionsByDate {
  dateGroup: string;
  data: TransactionLight[];
}

const PaymentScreen = (props: { role: UserRole }) => {
  const isMobile: boolean = useResponsive();
  const { t } = useTranslation();
  const { role } = props;
  const location = useLocation();

  const [sortedPayments, setSortedPayments] = useState<TransactionsByDate[]>([]);
  const [{ transactions }] = useObservable(transactionsQuery.transactions$);
  const [{ transactionTotal }] = useObservable(transactionsQuery.totals$);
  const [filters] = useObservable(transactionsQuery.filters$);
  const [accountBalance, setAccountBalance] = useState<string>();

  const searchTransactions = useEffectFn(searchTransactionEffects);
  const searchTransactionsTotal = useEffectFn(searchTransactionTotalEffects);
  const [selectedTransaction, setSelectedTransaction] = useState<TransactionLight | undefined>();
  const [modalOpen, setModalOpen] = useState(false);
  const [editmodalOpen, setEditModalOpen] = useState(false);
  const [exportModalOpen, setExportModalOpen] = useState(false);
  const [{ company }] = useObservable(sessionQuery.company$);

  useEffect(() => {
    sessionService.getCompany().subscribe();
  }, []);

  const [periodModalOpen, setPeriodModalOpen] = useState(false);

  const [expanded, setExpanded] = React.useState<number | false>(false);

  const handleChange = (panel: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  // get lasts ASP transactions from the same card as the current selected transaction
  const getPreviousASPTransaction = () => {
    if (selectedTransaction && selectedTransaction.serviceCategory?.type === "ASP") {
      // get the ASP transactions from the same card as the current selected transaction in every date group in the sortedPayments array
      const aspTransactions = sortedPayments.map((dateGroup) => {
        return dateGroup.data.filter((transaction) => {
          return transaction?.card?.id === selectedTransaction?.card?.id && transaction.serviceCategory?.type === "ASP";
        });
      });
      const flatASPTransactions = aspTransactions.flat();
      // get the last ASP transaction before the current selected transaction
      const index = flatASPTransactions.findIndex((transaction) => transaction.id === selectedTransaction.id);
      if (index !== -1 && index < flatASPTransactions.length - 1) {
        const previousASPTransaction = flatASPTransactions[index + 1];
        return previousASPTransaction;
      }
    }
    return [];
  };

  useEffect(() => {
    transactionServices.setFilters({ search: location.state?.categoryName, userId: location.state?.userId });
  }, [location]);

  useEffect(() => {
    getAccountBalance().then((x) => {
      if (x) setAccountBalance(x[0].balances.available.value);
    });
  }, []);

  useEffect(() => {
    const paymentId = paymentService.getPaymentIdFromUrl();
    if (paymentId && transactions.length > 0) {
      const transaction = transactions.find((payment) => {
        return payment.id === paymentId;
      });
      setSelectedTransaction(transaction);
      setModalOpen(true);
      setEditModalOpen(true);
      setTimeout(() => {
        setEditModalOpen(false);
      }, 2000);
      paymentService.removePaymentIdFromLocal();
    }
  }, [transactions]);

  useEffect(() => {
    searchTransactions(filters);
  }, [filters, searchTransactions]);

  useEffect(() => {
    searchTransactionsTotal(filters);
  }, [filters, searchTransactionsTotal]);

  useEffect(() => {
    transactionServices.getCompanyExportOrder().subscribe({
      error: (err) => enqueueSnackbar(err.text, err.options),
    });
  }, []);

  const labelForDateGroup = (date: string) => {
    switch (date) {
      case "thisWeek":
        return "Cette semaine";
      case "lastWeek":
        return "La semaine dernière";
      default:
        return date;
    }
  };

  useEffect(() => {
    const cleanedPaiements = filterDuplicatePayments(transactions);
    const sortedPaiements = groupObjectsByDate(cleanedPaiements);
    setSortedPayments(sortedPaiements);
  }, [transactions]);

  const closePeriod = () => {
    setPeriodModalOpen(true);
    //APIAxios({...APIRoutes.GETExportTransaction()})
  };

  const baseFilters = {
    status: undefined,
    noProof: undefined,
    isAmountMatch: undefined,
    swanStatusInfo: undefined,
  };

  const formattedAccountBalance = new Intl.NumberFormat("fr-FR", { style: "currency", currency: "EUR" }).format(
    Number(accountBalance ?? 0)
  );

  return (
    <Stack
      maxHeight={!isMobile ? "calc(100vh - 265px)" : undefined}
      overflow={!isMobile ? "auto" : undefined}
      width={"100%"}
    >
      {modalOpen && selectedTransaction && (
        <TransactionDetail
          transactionId={selectedTransaction.id}
          previousASP={getPreviousASPTransaction}
          openEdit={editmodalOpen}
          handleClose={() => {
            transactionServices.getTransactionTotal(filters).subscribe({
              error: (err) => enqueueSnackbar(err.text, err.options),
            });
            transactionServices.removeTransactionById(selectedTransaction.id);
            searchTransactions(filters);
            setSelectedTransaction(undefined);
            setModalOpen(false);
          }}
        />
      )}
      {exportModalOpen && (
        <ExportTransactions
          exportModalOpen={exportModalOpen}
          handleClose={() => {
            setExportModalOpen(false);
          }}
        />
      )}
      {periodModalOpen && role !== UserRole.EMPLOYEE && <ClosePeriod handleClose={() => setPeriodModalOpen(false)} />}

      <Stack p={isMobile ? 0 : 3} gap={2} width="100%" marginBottom={3}>
        <Stack pb={3} direction={isMobile ? "column" : "row"} gap={2} width="100%" justifyContent="space-between">
          <Typography fontSize="30px" fontWeight="800" style={isMobile ? { marginTop: "40px" } : undefined}>
            {t("payments.title")}
          </Typography>
          <Stack direction="row" gap={2}>
            <BasicSearchbar
              value={filters.search}
              onChange={(value) => transactionServices.setFilters({ search: value })}
              placeholder={t("global.search")}
            />
            {!isMobile && (
              <Button
                style={{ height: 52, width: "100%" }}
                color="secondary"
                onClick={() => setExportModalOpen(true)}
                startIcon={<img alt="" src={Export} />}
              >
                Exporter
              </Button>
            )}
          </Stack>
        </Stack>
        {role === UserRole.EMPLOYEE && company?.hasDrivers && <SharedCardSection />}
        {role !== UserRole.EMPLOYEE && (
          <Stack direction="row" gap={2} width="100%">
            <Button style={{ height: 52 }} variant="contained" onClick={() => closePeriod()}>
              {t("Fermer une période")}
            </Button>
            <Stack
              sx={{
                bgcolor: Colors.green,
                color: "white",
                borderRadius: "12px",
                alignItems: "center",
                justifyContent: "center",
                p: "0 20px",
              }}
            >
              <Typography>
                <Trans i18nKey={"accountBalance"} values={{ accountBalance: formattedAccountBalance }} />
              </Typography>
            </Stack>
          </Stack>
        )}
        <Grid container direction="row" spacing={3}>
          <Grid item xs>
            <FilterCard
              color={Colors.black}
              selected={
                filters.status === undefined && filters.noProof === undefined && filters.isAmountMatch === undefined
              }
              label={t("payments.allPayments")}
              data={transactionTotal?.allTotal ?? "0"}
              onClick={() =>
                transactionServices.setFilters({
                  ...baseFilters,
                })
              }
              total={transactionTotal?.allQuantity ?? 0}
            />
          </Grid>
          <Grid item xs>
            <FilterCard
              color={Colors.green}
              selected={filters.status === TransactionStatus.OK}
              label={t("OK")}
              onClick={() =>
                transactionServices.setFilters({
                  ...baseFilters,
                  status: TransactionStatus.OK,
                })
              }
              data={transactionTotal?.OKTotal ?? "0"}
              total={transactionTotal?.OKQuantity ?? 0}
            />
          </Grid>

          <Grid item xs>
            <FilterCard
              color={Colors.yellow}
              selected={filters.status === TransactionStatus.ACTION_REQUIRED}
              label={t("payments.toBeCompleted")}
              onClick={() =>
                transactionServices.setFilters({
                  ...baseFilters,
                  status: TransactionStatus.ACTION_REQUIRED,
                })
              }
              data={transactionTotal?.actionRequiredTotal ?? "0"}
              total={transactionTotal?.actionRequiredQuantity ?? 0}
            />
          </Grid>
          <Grid item xs>
            <FilterCard
              color={Colors.skyblue}
              selected={filters.swanStatusInfo === SwanStatusInfo.PENDING}
              label={t("payments.pending")}
              onClick={() =>
                transactionServices.setFilters({
                  ...baseFilters,
                  swanStatusInfo: SwanStatusInfo.PENDING,
                  status: TransactionStatus.PENDING,
                })
              }
              data={transactionTotal?.pendingTotal ?? "0"}
              total={transactionTotal?.pendingQuantity ?? 0}
            />
          </Grid>
          {role !== UserRole.EMPLOYEE && (
            <>
              <Grid item xs>
                <FilterCard
                  color={Colors.gray}
                  selected={filters.isAmountMatch === false}
                  label={t("payments.filter.OCRFailed")}
                  data={transactionTotal?.isAmountMatchFalseTotal ?? "0"}
                  total={transactionTotal?.isAmountMatchQuantity ?? 0}
                  onClick={() =>
                    transactionServices.setFilters({
                      ...baseFilters,
                      isAmountMatch: false,
                    })
                  }
                />
              </Grid>
              <Grid item xs>
                <FilterCard
                  color={Colors.gray}
                  selected={filters.noProof}
                  label={t("payments.filter.noJustif")}
                  data={transactionTotal?.noProofTotal ?? "0"}
                  total={transactionTotal?.noProofQuantity ?? 0}
                  onClick={() =>
                    transactionServices.setFilters({
                      ...baseFilters,
                      noProof: true,
                    })
                  }
                />
              </Grid>
            </>
          )}
          <Grid item xs>
            <FilterCard
              color={Colors.warning}
              selected={filters.status === TransactionStatus.FAILED}
              label={t("payments.filter.refused")}
              onClick={() =>
                transactionServices.setFilters({
                  ...baseFilters,
                  status: TransactionStatus.FAILED,
                })
              }
              data={transactionTotal?.failedTotal ?? ""}
              total={transactionTotal?.failedQuantity ?? 0}
            />
          </Grid>
        </Grid>
        <Stack>
          {sortedPayments.map((it, idx) => {
            const paymentsNotFailed = it.data.filter((transaction) => transaction.status !== TransactionStatus.FAILED);
            return (
              <Accordion
                key={idx}
                TransitionProps={{ unmountOnExit: true }}
                expanded={expanded === idx}
                onChange={handleChange(idx)}
              >
                <AccordionSummary sx={{ height: "60px !important" }} expandIcon={<img src={Chevron} alt="" />}>
                  <Stack direction="row" justifyContent="space-between" width="100%" paddingRight={1}>
                    <Typography fontSize={isMobile ? "14px" : "16px"} fontWeight="700">{`${labelForDateGroup(
                      it.dateGroup
                    )}`}</Typography>
                    <Stack direction="row" gap={1}>
                      <Typography fontSize={isMobile ? "14px" : "18px"} fontWeight="800">
                        {Math.round(
                          paymentsNotFailed.map((transaction) => transaction.amount).reduce((a, b) => a + b, 0) * 100
                        ) /
                          100 +
                          "€"}
                      </Typography>
                      <Typography
                        fontSize={isMobile ? "14px" : "16px"}
                        fontWeight="500"
                        color={Colors.secondaryText}
                      >{`(${paymentsNotFailed.length} paiements)`}</Typography>
                    </Stack>
                  </Stack>
                </AccordionSummary>
                {it.data.map((transactionLight) => {
                  return (
                    <AccordionDetails>
                      <PaymentCardComponent
                        transactionLight={transactionLight}
                        setModalOpen={setModalOpen}
                        setSelectedTransaction={setSelectedTransaction}
                      />
                    </AccordionDetails>
                  );
                })}
              </Accordion>
            );
          })}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default PaymentScreen;
