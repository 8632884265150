import { createEffectFn } from "@ngneat/effects";
import { debounceTime, distinctUntilChanged, Observable, switchMap } from "rxjs";
import { UserFilter } from "./user.model";
import { usersServices } from "./users.services";

export const searchUsersEffect = createEffectFn((param$: Observable<UserFilter>) => {
  return param$.pipe(
    debounceTime(300),
    distinctUntilChanged(),
    switchMap((filters) => usersServices.getUsers(filters))
  );
});

export const searchCompanyUsersEffect = createEffectFn((param$: Observable<UserFilter>) => {
  return param$.pipe(
    debounceTime(300),
    distinctUntilChanged(),
    switchMap((filters) => usersServices.getCompanyUsers(filters))
  );
});
