import { InputLabel, Stack, StandardTextFieldProps, TextField } from "@mui/material";
import { ElementRef, forwardRef } from "react";

interface TextFieldFormProps extends Omit<StandardTextFieldProps, "placeholder" | "variant"> {
  label?: string | null;
  ariaLabel?: "white";
  fullWidth?: boolean;
  placeholder?: string | null;
  variant?: "outlined" | "filled" | "standard";
}

export type Ref = ElementRef<"input">;

// Look forwardRef doc
// https://react-typescript-cheatsheet.netlify.app/docs/basic/getting-started/forward_and_create_ref/
// https://react.dev/reference/react/forwardRef

const TextFieldForm = forwardRef<Ref, TextFieldFormProps>((props, ref) => {
  const { label, placeholder, fullWidth, ariaLabel, variant, ...others } = props;
  return (
    <Stack width={fullWidth ? "100%" : undefined} spacing={1}>
      {!!label && (
        <InputLabel sx={{ color: ariaLabel?.includes("white") ? "black" : undefined, fontWeight: 600 }}>
          {label}
        </InputLabel>
      )}
      <Stack>
        <TextField ref={ref} {...others} aria-label={ariaLabel} fullWidth hiddenLabel variant={variant} />
      </Stack>
      {/* {!!others.helperText && (
        <Typography fontSize="12px" fontWeight={500}>
          {others.helperText}
        </Typography>
      )} */}
    </Stack>
  );
});

export default TextFieldForm;
