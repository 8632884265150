import { ChangeHandler, FieldErrors, FieldPath, FieldValues, RefCallBack, UseFormRegister } from "react-hook-form";

export type CustomRegisterType<TFieldName> = {
  error?: boolean;
  helperText?: any;
  onChange: ChangeHandler;
  onBlur: ChangeHandler;
  ref: RefCallBack;
  name: TFieldName;
  min?: string | number;
  max?: string | number;
  maxLength?: number;
  minLength?: number;
  pattern?: string;
  required?: boolean;
  disabled?: boolean;
  inputProps?: Record<string, unknown>;
};

export default function CustomRegister<
  TFieldValues extends FieldValues = FieldValues,
  TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(
  register: UseFormRegister<TFieldValues>,
  errors: FieldErrors<TFieldValues>
): (name: TFieldName) => CustomRegisterType<TFieldName> {
  return (name: TFieldName) => {
    return {
      ...register(name),
      error: !!errors[name],
      helperText: errors[name]?.message as string,
      inputProps: { className: errors[name] ? "error" : "" },
    };
  };
}
