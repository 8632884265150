import { FormEvent, useEffect, useState, useRef } from "react";
import {
  Button,
  Dialog,
  Grid,
  IconButton,
  Stack,
  Switch,
  TextField,
  Typography,
  InputAdornment,
  FormControlLabel,
  Checkbox,
  Dialog as MuiDialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import axios from "axios";
import { DatePicker } from "@mui/x-date-pickers";
import { TransactionFull, transactionServices, Tax } from "../../../../store/transaction";
import { useTranslation } from "react-i18next";
import Close from "../../../../assets/icons/icon_close_dark.svg";
import BasicTextfield from "../../../../components/inputs/BasicTextfield.component";
import BasicSelect from "../../../../components/inputs/BasicSelect.component";
import { Category, CategoryType } from "../../../../store/serviceCategories";
import APIAxios, { APIRoutes } from "../../../../api/axios.api";
import { enqueueSnackbar } from "notistack";
import { Delete } from "@mui/icons-material";
import FileSelect from "../../../../components/inputs/FileSelect.component";
import Upload from "../../../../assets/icons/icon_upload_grey.svg";
import { finalize } from "rxjs";
import { NumericFormat } from "react-number-format";
import { Colors } from "../../../../constants/colors.constants";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { calculateDistance as calculateBirdFlightDistance } from "../../../../utils/distanceUtils";
import { getDistanceInfo } from "../../../../api/getDistanceInfo";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import NoProofModal from "./NoProofModal";
import { useObservable } from "@ngneat/react-rxjs";
import { sessionQuery } from "src/store/session";
import { UserRole } from "src/store/users";

interface EditTransactionProps {
  transaction: TransactionFull;
  previousASP?: () => Object;
  handleClose: () => void;
}

interface Location {
  label: string;
  value: any;
}

const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

const EditTransaction = (props: EditTransactionProps) => {
  const { transaction, handleClose, previousASP } = props;

  const [data, setData] = useState<Partial<TransactionFull>>({
    ...transaction,
    invoice: transaction.noProof ? undefined : transaction.invoice,
  });
  const [user] = useObservable(sessionQuery.user$);
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState<Category[]>([]);
  const [taxes, setTaxes] = useState<Tax[]>(transaction.taxes || [{ amount: 0, percent: 0 }]);
  const [file, setFile] = useState<File | undefined>(undefined);
  const [noProofModal, setNoProofModal] = useState(false);

  const { t } = useTranslation();

  const [fromLocation, setFromLocation] = useState<Location | null>(null);
  const [toLocation, setToLocation] = useState<Location | null>(null);
  const [previousASPTransaction, setPreviousASPTransaction] = useState<Object | null>(null);
  const [kilometerWarning, setKilometerWarning] = useState(false);
  const [kilometerMessage, setKilometerMessage] = useState("");
  const [{ company }] = useObservable(sessionQuery.company$);
  const [ocrFalseModal, setOcrFalseModal] = useState(false);
  const inputFileRef = useRef<HTMLButtonElement>(null);

  const dataToComplete = {
    justificatif: company?.isInvoiceRequired && !transaction?.invoice,
    motif: company?.isReasonRequired && !transaction?.reason,
    distanceKmEstimate:
      (data.serviceCategory?.type ?? transaction?.serviceCategory?.type) === CategoryType.MSP &&
      !transaction?.distanceKmEstimate,
    vehicleKilometersAtTransaction:
      (data.serviceCategory?.type ?? transaction?.serviceCategory?.type) === CategoryType.ASP &&
      (data.serviceCategory?.isSubjectToKilometers ?? transaction?.serviceCategory?.isSubjectToKilometers) &&
      !transaction?.vehicleKilometersAtTransaction,
  };
  const rotatingIconStyle = {
    color: "white",
    animation: "rotationHourglass 2s infinite linear",
  };

  const [confirmClose, setConfirmClose] = useState(false);

  const hasUnsavedChanges = () => {
    for (const key in data) {
      if (key === "quantity") continue;
      if (data[key as keyof typeof data] !== transaction[key as keyof typeof transaction]) {
        return true;
      }
    }

    return false;
  };

  const handleCloseConfirmation = () => {
    if (hasUnsavedChanges()) {
      setConfirmClose(true);
    } else {
      handleClose();
    }
  };

  const handleCancelClose = () => {
    setFile(undefined);
    setConfirmClose(false);
  };

  const handleSaveAndClose = (evt: React.MouseEvent) => {
    setConfirmClose(false);
    handleValidate(new Event("submit") as unknown as FormEvent);
  };

  const modifiedHandleClose = () => {
    setConfirmClose(false);
    handleClose();
  };

  useEffect(() => {
    updateData("taxes")(taxes);
  }, [taxes]);

  const getCoordinates = async (placeId: string) => {
    try {
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?place_id=${placeId}&key=${API_KEY}`
      );
      const { lat, lng } = response.data.results[0]?.geometry?.location;
      return { lat, lng };
    } catch (error) {
      console.error("Error fetching coordinates:", error);
      throw new Error("Failed to fetch coordinates");
    }
  };

  const getCategories = async (): Promise<void> => {
    return await APIAxios({ ...APIRoutes.GETAllCategories() }).then((res) => {
      if (res.data) {
        setCategories(res.data);
      }
    });
  };

  const updateData = (name: string) => (value: any) => {
    setData((prevData) => ({ ...prevData, [name]: value }));
  };

  useEffect(() => {
    getCategories();
    if (!data.quantity || data.quantity === 0) {
      updateData("quantity")(1);
    }
  }, []);

  useEffect(() => {
    if (data.serviceCategory?.type === CategoryType.ASP) {
      previousASP && setPreviousASPTransaction(previousASP());
    }
  }, [data.serviceCategory]);

  useEffect(() => {
    const fetchCoordsAndCalculateDistance = async () => {
      if (
        fromLocation &&
        fromLocation.value &&
        fromLocation.value.place_id &&
        toLocation &&
        toLocation.value &&
        toLocation.value.place_id
      ) {
        try {
          const originCoords = await getCoordinates(fromLocation.value.place_id);
          const destinationCoords = await getCoordinates(toLocation.value.place_id);
          try {
            let result;
            if (data?.serviceCategory?.name === "Avion") {
              result = await calculateBirdFlightDistance(originCoords, destinationCoords);
            } else {
              result = await getDistanceInfo(originCoords, destinationCoords, "driving");
            }
            // Double the distance if the round trip option is enabled
            result.distance = Math.round(result.distance * 100) / 100;
            if (data.isRoundTrip) {
              const doubledDistance = result.distance * 2;
              updateData("distanceKmEstimate")(doubledDistance); // Update distanceKmEstimate
            } else {
              updateData("distanceKmEstimate")(result.distance); // Update distanceKmEstimate
            }
            updateData("fromLocation")(fromLocation.label);
            updateData("toLocation")(toLocation.label);
          } catch (error) {
            console.error("Error calculating distance:", error);
          }
        } catch (error) {
          console.error("Error fetching coordinates:", error);
        }
      }
    };
    fetchCoordsAndCalculateDistance();
  }, [fromLocation, toLocation, data.isRoundTrip, data.serviceCategory?.name]);

  const handleValidate = (evt: FormEvent) => {
    evt.preventDefault();
    if (!data.serviceCategory) {
      enqueueSnackbar(t("categories.emptyFieldError"), { variant: "error" });
      return;
    }
    const incompleteFields = Object.keys(dataToComplete).filter(
      (key) =>
        key === "justificatif" &&
        !file &&
        !data.noProof &&
        dataToComplete[key as keyof typeof dataToComplete] &&
        !data[key as keyof typeof data]
    );
    if (incompleteFields.length > 0) {
      if (!(incompleteFields.length === 1 && incompleteFields[0] === "justificatif")) {
        enqueueSnackbar(t("common.incompleteFieldsError"), { variant: "error" });
        return;
      }
    }
    setLoading(true);
    if (file) {
      transactionServices.uploadInvoice(transaction.id, file, data.serviceCategory?.name === "Carburant").subscribe({
        error: (err) => {
          setLoading(false);
          enqueueSnackbar(err.text, err.options);
        },
        next: (value) => {
          setLoading(false);
          updateTransaction(value.key);
        },
      });
    } else {
      updateTransaction();
    }
  };

  const updateTransaction = (invoice?: string) => {
    delete data.fuelType;
    delete data.fuelVolumeLiters;
    const transactionData = {
      ...data,
      invoice: invoice,
      taxes: taxes,
    };
    if (!data.quantity || data.quantity === 0) {
      transactionData.quantity = 1;
    }
    transactionServices
      .completeTransaction(transactionData)
      .pipe(finalize(() => setLoading(false)))
      .subscribe({
        error: (err) => {
          enqueueSnackbar(err.text, err.options);
        },
        next: (response) => {
          if (!response.isAmountMatch && response.invoice && !response.noProof) {
            setOcrFalseModal(true);
          } else {
            handleClose();
          }
        },
      });
  };

  const [lastUpdated, setLastUpdated] = useState<"percent" | "amount" | null>(null);
  const updateTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const updateTaxPercent = (percent: number, index: number) => {
    if (lastUpdated === "amount") return;
    setLastUpdated("percent");

    const newTaxes = [...taxes];
    if (newTaxes.length === 1) {
      const taxAmount = (transaction.amount * percent) / (100 + percent);
      newTaxes[index] = { amount: taxAmount, percent: percent };
    } else {
      newTaxes[index] = { ...newTaxes[index], percent: percent };
    }
    setTaxes(newTaxes);

    if (updateTimeoutRef.current) clearTimeout(updateTimeoutRef.current);
    updateTimeoutRef.current = setTimeout(() => setLastUpdated(null), 100);
  };

  const updateTvaAmount = (amount: number, index: number) => {
    if (lastUpdated === "percent") return;
    setLastUpdated("amount");

    const newTaxes = [...taxes];

    if (newTaxes.length === 1) {
      const taxPercent = (amount / (transaction.amount - amount)) * 100;
      newTaxes[index] = { amount: amount, percent: taxPercent };
    } else {
      newTaxes[index] = { ...newTaxes[index], amount: amount };
    }

    setTaxes(newTaxes);

    if (updateTimeoutRef.current) clearTimeout(updateTimeoutRef.current);
    updateTimeoutRef.current = setTimeout(() => setLastUpdated(null), 100);
  };

  const updateAllTaxPercents = (value: string) => {
    const newTaxes = taxes.map((tax) => {
      if (taxes.length === 1) {
        // update the tax amount of the first tax based on the percentage
        const taxAmount = transaction.amount / (1 + 1 / (Number(value) / 100));
        return { amount: taxAmount, percent: Number(value) };
      } else {
        return { ...tax, percent: Number(value) };
      }
    });
    setTaxes(newTaxes);
  };

  const removeTax = (index: number) => {
    const newTaxes = [...taxes];
    newTaxes.splice(index, 1);
    setTaxes(newTaxes);
  };

  const materialUITextFieldProps = (): any => {
    return {
      color: "primary",
      fontColor: Colors.black,
      ariaLabel: "white",
    };
  };

  const handleKilometerChange = (value: number) => {
    if (previousASPTransaction && (previousASPTransaction as { id: string }).id) {
      transactionServices.getFullTransaction((previousASPTransaction as { id: string }).id).subscribe((data) => {
        const pastKm = data?.vehicleKilometersAtTransaction ?? 0;
        if (pastKm > value) {
          setKilometerWarning(true);
          setKilometerMessage(`Le kilométrage precedent était : ${pastKm} km`);
        }
      });
    }
    setKilometerWarning(false);
  };

  // Function to get the Place ID from an address
  const getPlaceIdFromAddress = async (address: string): Promise<string> => {
    try {
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${API_KEY}`
      );
      const placeId = response.data.results[0]?.place_id;
      return placeId || "";
    } catch (error) {
      console.error("Error fetching Place ID:", error);
      throw new Error("Failed to fetch Place ID");
    }
  };

  // Use the function to retrieve the Place ID of "Marseille, France".
  const fetchPlaceId = async (address: string): Promise<string> => {
    try {
      const placeId = await getPlaceIdFromAddress(address);
      return placeId;
    } catch (error) {
      console.error("Error fetching Place ID:", error);
      throw error; // Propagate the error for the calling code to handle if necessary
    }
  };

  const handleCloseNoProofModal = () => {
    setNoProofModal(false);
    setFile(undefined);
    updateData("noProof")(false);
  };

  const handleValidateNoProofModal = () => {
    setNoProofModal(false);
  };

  // Set default locations if transaction exists
  useEffect(() => {
    const fetchData = async () => {
      if (transaction) {
        if (transaction.fromLocation) {
          try {
            const from = await fetchPlaceId(transaction.fromLocation + "");
            setFromLocation({ label: transaction.fromLocation, value: { place_id: from } });
          } catch (error) {
            console.error("Error setting fromLocation:", error);
          }
        }

        if (transaction.toLocation) {
          try {
            const to = await fetchPlaceId(transaction.toLocation + "");
            setToLocation({ label: transaction.toLocation, value: { place_id: to } });
          } catch (error) {
            console.error("Error setting toLocation:", error);
          }
        }
      }
    };
    fetchData();
  }, []);

  const handleFileChange = (file?: File) => {
    setFile(file);
  };

  useEffect(() => {
    if (file) {
      handleValidate(new Event("submit") as unknown as FormEvent);
    }
  }, [file]);

  return (
    <>
      <Dialog open={true} maxWidth="md" style={{ borderRadius: "8px" }}>
        {loading && (
          <Grid
            bgcolor="rgba(0, 0, 0, 0.5)"
            width={"100%"}
            height={"100%"}
            position="absolute"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            gap={2}
            zIndex={200}
          >
            <Typography fontSize="18px" fontWeight={600} color="white">
              {t("payments.edit.instructionReceived")}
            </Typography>
            <HourglassEmptyIcon style={rotatingIconStyle} />
          </Grid>
        )}
        <form onSubmit={handleValidate}>
          <Stack direction="column" gap={4} p={4}>
            <Stack direction="row" justifyContent="space-between">
              <Typography fontSize="30px" fontWeight={800}>
                {t("payments.edit.title")}
              </Typography>
              <IconButton onClick={handleCloseConfirmation}>
                <img alt="" src={Close} />
              </IconButton>
            </Stack>
            <Grid container gap={2}>
              <Grid item xs>
                <b>{`${transaction?.amount} € `}</b>
                Payé à <b>{`${transaction?.merchantName} `}</b>
                le{" "}
                <b>
                  {`${new Date(transaction.date).toLocaleDateString("fr-FR", {
                    weekday: "long",
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })} ${new Date(transaction.date).toLocaleTimeString("fr-FR", {
                    hour: "2-digit",
                    minute: "2-digit",
                  })}`}
                </b>
              </Grid>
            </Grid>
            <Grid container gap={2}>
              <Grid item xs>
                <BasicSelect
                  ariaLabel="white"
                  color="secondary"
                  handleChange={(value) => {
                    updateData("serviceCategory")(categories.find((cat: Category) => cat.id === value));
                    if (value === categories.find((cat: Category) => cat.name === "Invitations")?.id) {
                      (!data.quantity || data.quantity === 1) && updateData("quantity")(2);
                      const newTaxes = data.taxes || [];
                      newTaxes.push({ amount: 0, percent: 0 });
                      updateTaxPercent(10, newTaxes.length - 1);
                    }
                  }}
                  items={categories.map((it) => ({ label: it.name, value: it.id }))}
                  fullWidth
                  value={data.serviceCategory?.id || ""}
                  placeholder={"Catégorie"}
                />
              </Grid>
              <Grid item xs>
                <DatePicker
                  label="Date de prestation"
                  value={transaction.dateOfService ? Date.parse(transaction.date) : undefined}
                  onChange={updateData("dateOfService")}
                  aria-label="white"
                  format="dd-MM-yyyy"
                  sx={{
                    "& .MuiFormLabel-root": {
                      color: "rgba(0, 0, 0, 0.87)",
                    },
                  }}
                />
              </Grid>
            </Grid>
            <Grid container gap={2} direction="row">
              {data.serviceCategory?.type === CategoryType.OSP && (
                <Grid item xs>
                  <BasicTextfield
                    ariaLabel="white"
                    label="Quantité"
                    placeholder="Quantité"
                    defaultValue={transaction?.serviceCategory?.name === "Invitation" ? "2" : "1"}
                    value={data.quantity?.toString()}
                    onChange={updateData("quantity")}
                  />
                </Grid>
              )}
              <Grid item xs>
                {!data.noProof && (
                  <FileSelect
                    fullwidth
                    ref={inputFileRef}
                    endIcon={<img src={Upload} alt="" />}
                    ariaLabel="select-file-textfield"
                    inputLabel={"Justificatif"}
                    disabled={!transaction}
                    file={file}
                    handleFileChange={handleFileChange}
                    required={dataToComplete.justificatif}
                    label={transaction.invoice ?? file?.name ?? t("Justificatif") ?? ""}
                  />
                )}
              </Grid>
            </Grid>
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked={transaction.noProof}
                    checked={data.noProof}
                    onChange={(e) => {
                      const isChecked = e.target.checked;
                      setNoProofModal(isChecked);
                      if (!isChecked) {
                        updateData("noProof")(false);
                      } else {
                        updateData("noProof")(true);
                        setFile(undefined);
                      }
                    }}
                  />
                }
                label={t("payments.edit.noProof")}
              />
            </Grid>
            {data.serviceCategory?.type === CategoryType.ASP && (
              <Grid container gap={2}>
                <Grid item xs>
                  <BasicTextfield
                    label="Kilométrage"
                    placeholder="Kilométrage"
                    ariaLabel="white"
                    value={data.vehicleKilometersAtTransaction?.toString()}
                    onChange={(value) => {
                      updateData("vehicleKilometersAtTransaction")(Number(value));
                      handleKilometerChange(Number(value));
                    }}
                    {...(kilometerWarning && {
                      error: kilometerMessage,
                    })}
                    required={dataToComplete.vehicleKilometersAtTransaction}
                  />
                </Grid>
              </Grid>
            )}
            <Grid container gap={2}>
              <Grid item xs>
                <BasicTextfield
                  label="Motif"
                  placeholder="Motif"
                  ariaLabel="white"
                  value={data.reason}
                  onChange={updateData("reason")}
                  required={dataToComplete.motif}
                />
              </Grid>
            </Grid>
            {data.serviceCategory?.type === CategoryType.MSP && (
              <>
                <Stack direction="row" justifyContent="space-between">
                  <Typography fontSize={14} fontWeight={500}>
                    Aller-retour
                  </Typography>
                  <Switch checked={data.isRoundTrip} onChange={(e) => updateData("isRoundTrip")(e.target.checked)} />
                </Stack>
                <Grid container gap={2}>
                  <Grid item xs>
                    <GooglePlacesAutocomplete
                      apiKey={API_KEY}
                      selectProps={{
                        value: fromLocation,
                        // onChange: (location)=>updateData('fromLocation')(location?.label),
                        onChange: setFromLocation,
                        placeholder: "Lieu de départ",
                      }}
                    />
                  </Grid>
                  <Grid item xs>
                    <GooglePlacesAutocomplete
                      apiKey={API_KEY}
                      selectProps={{
                        value: toLocation,
                        // onChange: (location)=>updateData('toLocation')(location?.label),
                        onChange: setToLocation,
                        placeholder: "Lieu d'arrivée",
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container gap={2}>
                  <Grid item>
                    <Grid item>
                      <BasicTextfield
                        label="Distance parcourue (Km)"
                        placeholder="Distance parcourue (Km)"
                        ariaLabel="white"
                        value={data.distanceKmEstimate?.toString() ?? ""}
                        onChange={updateData("distanceKmEstimate")}
                        endAdornment={<InputAdornment position="end">km</InputAdornment>}
                        required={dataToComplete.distanceKmEstimate}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
            <>
              {taxes?.map((tax, idx) => {
                return (
                  <Stack direction="row" key={idx}>
                    <Grid container gap={2}>
                      <Grid item xs>
                        <Typography sx={{ fontSize: "12px" }}>{idx === 0 ? "Pourcentage TVA" : ""}</Typography>
                        <NumericFormat
                          disabled={!!(transaction.taxes[idx]?.percent && user?.role === UserRole.EMPLOYEE)}
                          suffix="%"
                          value={tax.percent}
                          decimalSeparator=","
                          decimalScale={tax.percent === 0 ? 0 : 2}
                          fixedDecimalScale
                          customInput={TextField}
                          aria-label={"white"}
                          onValueChange={(values) => {
                            if (lastUpdated !== "amount" && values.floatValue) updateTaxPercent(values.floatValue, idx);
                          }}
                          {...materialUITextFieldProps}
                        />
                      </Grid>
                      <Grid item xs>
                        <Stack direction={"row"} alignItems="flex-end">
                          <Stack direction={"column"}>
                            <Typography sx={{ fontSize: "12px" }}>{idx === 0 ? "Montant TVA" : ""}</Typography>
                            <NumericFormat
                              disabled={!!(transaction.taxes[idx]?.amount && user?.role === UserRole.EMPLOYEE)}
                              suffix="€"
                              value={tax.amount}
                              decimalSeparator=","
                              decimalScale={tax.amount === 0 ? 0 : 2}
                              fixedDecimalScale
                              onValueChange={(values) => {
                                if (lastUpdated !== "percent" && values.floatValue)
                                  updateTvaAmount(values.floatValue, idx);
                              }}
                              sx={{ color: "black !important" }}
                              customInput={TextField}
                              aria-label={"white"}
                              {...materialUITextFieldProps}
                            />
                          </Stack>
                          {!(transaction.taxes[idx]?.amount && user?.role === UserRole.EMPLOYEE) && (
                            <IconButton color="error" sx={{ bottom: "8px" }} onClick={() => removeTax(idx)}>
                              <Delete />
                            </IconButton>
                          )}
                        </Stack>
                      </Grid>
                    </Grid>
                  </Stack>
                );
              })}
            </>
            {!(transaction.taxes.some((x) => x.amount > 0 || x.percent > 0) && user?.role === UserRole.EMPLOYEE) && (
              <Button
                sx={{ color: Colors.primary }}
                onClick={() => {
                  const newTaxes = data.taxes || [];
                  newTaxes.push({ amount: 0, percent: 0 });
                  updateData("taxes")(newTaxes);
                }}
              >
                Ajouter une taxe
              </Button>
            )}
            <Stack alignItems="center">
              <Button type="submit" color="secondary" disabled={loading} style={{ height: "52px" }}>
                {t("payments.edit.title")}
              </Button>
            </Stack>
          </Stack>
        </form>
        {noProofModal && (
          <NoProofModal
            updateData={updateData}
            handleFileChange={handleFileChange}
            handleClose={handleCloseNoProofModal}
            updateAllTaxPercents={updateAllTaxPercents}
            handleValidateNoProofModal={handleValidateNoProofModal}
          />
        )}
      </Dialog>
      <MuiDialog open={confirmClose} onClose={handleCancelClose}>
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between">
            <Typography fontSize="30px" fontWeight={800}>
              {t("common.confirmAction")}
            </Typography>
            <IconButton onClick={handleCancelClose}>
              <img alt="" src={Close} />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{t("payments.edit.saveChangesPrompt")}</DialogContentText>
        </DialogContent>
        <Stack spacing={3} p={"10px"} direction="row" justifyContent={"center"}>
          <Button onClick={handleSaveAndClose} color="success" autoFocus>
            {t("common.save")}
          </Button>
          <Button onClick={modifiedHandleClose} color="error">
            {t("common.dontSave")}
          </Button>
        </Stack>
      </MuiDialog>
      <MuiDialog open={ocrFalseModal} onClose={() => setOcrFalseModal(false)}>
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between">
            <Typography fontSize="30px" fontWeight={800}>
              {t("payments.edit.ocrFalseModal.title")}
            </Typography>
            <IconButton onClick={() => setOcrFalseModal(false)}>
              <img alt="" src={Close} />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{t("payments.edit.saveChangesPrompt")}</DialogContentText>
        </DialogContent>
        <Stack spacing={3} p={"10px"} direction="row" justifyContent={"center"}>
          <Button
            onClick={() => {
              setOcrFalseModal(false);
              inputFileRef.current?.click();
            }}
            color="success"
          >
            {t("payments.edit.ocrFalseModal.cancel")}
          </Button>
          <Button
            onClick={() => {
              setOcrFalseModal(false);
              handleClose();
            }}
            color="error"
            autoFocus
          >
            {t("payments.edit.ocrFalseModal.save")}
          </Button>
        </Stack>
      </MuiDialog>
    </>
  );
};

export default EditTransaction;
