import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";

import UnAuth from "./unAuth/UnAuth";
import Auth from "./auth/Auth";

import { sessionQuery, sessionService } from "../store/session";
import { finalize } from "rxjs";
import { useObservable } from "@ngneat/react-rxjs";
import { useLocation } from "react-router-dom";
import { paymentService } from "../store/payments/payments.services";
import SwanRedirectScreen from "./auth/adminCompany/SwanRedirect";
import { Pages } from "src/utils/routes.utils";

const Screens = () => {
  const location = useLocation();

  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(!!sessionQuery.token);

  const [{ user }] = useObservable(sessionQuery.isAuthenticated$);

  useEffect(() => {
    const handlePaymentIdFromURL = () => {
      const queryParams = new URLSearchParams(location.search);
      const paymentIdFromURL = queryParams.get("payment_id");
      if (paymentIdFromURL) {
        paymentService.setPaymentIdFromUrl(paymentIdFromURL);
      }
    };
    handlePaymentIdFromURL();
  }, [location.search]);

  useEffect(() => {
    if (sessionQuery.token) {
      sessionService
        .fetchCurrentUser()
        .pipe(finalize(() => setLoading(false)))
        .subscribe({
          error: (err) => enqueueSnackbar(err.text, err.options),
        });
    } else {
      setLoading(false);
    }
  }, [enqueueSnackbar]);

  const isAuthenticated = !!user;

  if (loading) return null;

  if (location.pathname.includes(Pages.SWAN_REDIRECT)) return <SwanRedirectScreen />;

  if (isAuthenticated) {
    return <Auth />;
  }
  return <UnAuth />;
};

export default Screens;
