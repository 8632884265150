import { FormEvent, useEffect, useState } from "react";
import { Button, Dialog, IconButton, Stack, Typography } from "@mui/material";
import Close from "../../../../assets/icons/icon_close_dark.svg";
import {
  ExportTransaction,
  ExportTransactionFieldsInit,
  ExportTransactionFilters,
  transactionServices,
  transactionsQuery,
} from "../../../../store/transaction";
import { useTranslation } from "react-i18next";
import FormExportTransaction from "./FormExportTransaction";
import { enqueueSnackbar } from "notistack";
import { useObservable } from "@ngneat/react-rxjs";
import APIAxios, { APIRoutes } from "../../../../api/axios.api";
import { downloadZip } from "../../../../utils/Document";
import SnackError from "../../../../utils/errors.utils";
import dayjs from "dayjs";

interface ExportTransactionsProps {
  handleClose: () => void;
  exportModalOpen: boolean;
}

const ExportTransactions = (props: ExportTransactionsProps) => {
  const { handleClose, exportModalOpen } = props;
  const { t } = useTranslation();
  const [filters] = useObservable(transactionsQuery.filters$);
  const [exportTransactionFilters, setExportTransactionFilters] = useState<Partial<ExportTransactionFilters>>({
    status: filters.status,
    isUpdatable: undefined,
  });
  const updateExportTransactionFilters = (name: string) => (value: any) =>
    setExportTransactionFilters({ ...exportTransactionFilters, [name]: value });

  const [fieldsString, setFieldsString] = useState<string[]>([]);
  const updateFieldsString = (newFields: string[]) => {
    setFieldsString(newFields);
  };

  const [exportTransactionFields, setExportTransactionFields] = useState<ExportTransaction>({
    ...ExportTransactionFieldsInit,
  });
  const [loading, setLoading] = useState(false);
  const [companyExportTransactionConfig] = useObservable(transactionsQuery.companyExportTransactionConfig$);

  useEffect(() => {
    if (companyExportTransactionConfig) {
      setExportTransactionFields(companyExportTransactionConfig);
    }
  }, [exportModalOpen]);

  useEffect(() => {
    if (exportModalOpen) {
      const filteredObject = Object.entries(exportTransactionFields)
        .filter(([key, value]) => value > 0)
        .sort((a, b) => a[1] - b[1]);

      const array = filteredObject.map((item) => item[0]) as string[];
      setFieldsString(array);
    }
  }, [exportModalOpen, exportTransactionFields]);

  const formHandleError = () => {
    if (exportTransactionFilters?.from && exportTransactionFilters?.to) {
      const fromDate = new Date(Date.parse(exportTransactionFilters.from));
      const toDate = new Date(Date.parse(exportTransactionFilters.to));

      if (fromDate > toDate) {
        return enqueueSnackbar(t("global.errors.startDateMustBeBeforeEndDate"), { variant: "error" });
      }
    }
  };
  const convertFieldsArrayToExportTransaction = () => {
    const updatedObject: Record<string, number> = { ...ExportTransactionFieldsInit };

    fieldsString.forEach((property, index) => {
      updatedObject[property] = index + 1;
    });
    return updatedObject;
  };

  const handleValidate = async (evt: FormEvent) => {
    evt.preventDefault();
    formHandleError();
    await handleExport(evt);
  };

  const handleExport = async (evt: FormEvent) => {
    evt.preventDefault();
    setLoading(true);
    const exportTransaction = convertFieldsArrayToExportTransaction();
    try {
      const exportResponse = await APIAxios({
        ...APIRoutes.POSTExportTransaction(),
        responseType: "blob",
        data: {
          ...exportTransaction,
        },
        params: {
          ...exportTransactionFilters,
          from: exportTransactionFilters.from
            ? dayjs(exportTransactionFilters.from).add(2, "hours").toISOString()
            : undefined,
          to: exportTransactionFilters.to
            ? dayjs(exportTransactionFilters.to).add(2, "hours").toISOString()
            : undefined,
        },
      });
      const exportAccountingResponse = await APIAxios({
        ...APIRoutes.POSTExportAccounting(),
        responseType: "blob",
        data: {
          ...exportTransaction,
        },
        params: {
          ...exportTransactionFilters,
          from: exportTransactionFilters.from
            ? dayjs(exportTransactionFilters.from).add(2, "hours").toISOString()
            : undefined,
          to: exportTransactionFilters.to
            ? dayjs(exportTransactionFilters.to).add(2, "hours").toISOString()
            : undefined,
        },
      });
      await downloadZip(exportAccountingResponse.data, exportResponse.data, `exports_${new Date().toISOString()}`);
      const fetchExportResponse = await APIAxios({ ...APIRoutes.GETCompanyExportTransaction() });
      transactionServices.setExportTransaction(fetchExportResponse.data);
      enqueueSnackbar(t("payments.export.success"), { variant: "success" });
      setLoading(false);
      handleClose();
    } catch (err: any) {
      setLoading(false);
      throw new SnackError((err.response as any)?.data?.message, "error");
    }
  };

  return (
    <Dialog open={true} maxWidth="md" style={{ borderRadius: "8px", overflow: "unset!important" }} fullWidth>
      <form onSubmit={handleValidate}>
        <Stack p={4} spacing={4}>
          <Stack direction="row" justifyContent="space-between">
            <Typography fontSize="30px" fontWeight={800}>
              {t("payments.export.title")}
            </Typography>
            <IconButton onClick={handleClose}>
              <img alt="" src={Close} />
            </IconButton>
          </Stack>
          <FormExportTransaction
            updateExportTransactionFilters={updateExportTransactionFilters}
            exportTransactionFilters={exportTransactionFilters}
            fieldsString={fieldsString}
            updateFieldsString={updateFieldsString}
          />
          <Stack width="100%" alignItems="center">
            <Button
              type="submit"
              disabled={loading}
              style={{ width: "215px", height: "52px" }}
              color="secondary"
              variant="contained"
              onClick={handleExport}
            >
              {t("payments.export.form.export")}
            </Button>
          </Stack>
        </Stack>
      </form>
    </Dialog>
  );
};

export default ExportTransactions;
