import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import { alpha, CircularProgress, Stack, Typography } from "@mui/material";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { Colors } from "../../../../../constants/colors.constants";
import { useResponsive } from "../../../../../utils/useResponsive";
import CategoryScore from "./CategoryScore.component";
import Score, { ScoreStatusTab } from "./Score.component";
import { ScoreDto } from "@store/scores";

interface PersonalScoreProps {
  scores: ScoreDto;
  from_date: Date;
  to_date: Date;
}

const PersonalScore = (props: PersonalScoreProps) => {
  const { scores, from_date, to_date } = props;
  const isMobile: boolean = useResponsive();

  const { t: t_dashboard } = useTranslation(undefined, {
    keyPrefix: "individualDashboard",
  });

  return (
    <Stack
      sx={{
        backgroundColor: alpha(Colors.primary, 0.3),
        borderRadius: "20px",
        padding: "20px",
        flex: 6,
        gap: "40px",
      }}
    >
      {!scores ? (
        <Stack>
          <CircularProgress />
        </Stack>
      ) : (
        <>
          <Stack
            sx={{
              flexDirection: isMobile ? "column" : "row",
              gap: "20px",
              alignItems: "flex-start",
              justifyContent: "space-between",
              padding: "10px",
              pt: "20px",
            }}
          >
            <Stack
              sx={{
                alignItems: "flex-start",
                gap: "20px",
                flexDirection: "row",
              }}
            >
              <Score score={scores.userScores.score} size={100} noLabel />
              <Stack>
                <Typography fontSize="22px" fontWeight="700">
                  {t_dashboard("yourScore")}
                </Typography>
                <Typography fontSize="16px" fontWeight="600" color={Colors.secondaryText}>
                  {t_dashboard("periodScore", {
                    from: format(from_date, "dd/MM/yyyy"),
                    to: format(to_date, "dd/MM/yyyy"),
                  })}
                </Typography>
                <Typography
                  fontSize="20px"
                  fontWeight="700"
                  color={
                    scores.userScores.score > ScoreStatusTab[0].limit
                      ? scores.userScores.score > ScoreStatusTab[1].limit
                        ? Colors.score.red
                        : Colors.score.yellow
                      : Colors.score.green
                  }
                >
                  {scores.userScores.score} g/km
                </Typography>
              </Stack>
            </Stack>
            {/* <Stack
              sx={{
                backgroundColor: alpha(Colors.white, 0.2),
                borderRadius: "10px",
                padding: "8px",
              }}
            >
              <Stack sx={{ alignItems: "flex-start" }}>
                <Typography fontSize="16px" fontWeight="600">
                  {t_dashboard("previousScore")}
                </Typography>
                <Typography fontSize="14px" fontWeight="500" color={Colors.secondaryText}>
                  {t_dashboard("periodScore", {
                    from: format(from_date, "dd/MM/yyyy"),
                    to: format(to_date, "dd/MM/yyyy"),
                  })}
                </Typography>
                <Stack flexDirection={"row"} gap={"10px"} alignItems={"center"}>
                  <Typography fontSize="18px" fontWeight="700">
                    {fakePreviousScore} g/km
                  </Typography>
                  {fakePreviousScore < scores.userScores.score ? (
                    <TrendingDownIcon sx={{ color: Colors.score.red, fontSize: "25px" }} />
                  ) : (
                    <TrendingUpIcon sx={{ color: Colors.score.green, fontSize: "25px" }} />
                  )}
                </Stack>
              </Stack>
            </Stack> */}
          </Stack>
          <Stack
            sx={{
              padding: "20px",
              gap: "20px",
              backgroundColor: alpha(Colors.white, 0.4),
              borderRadius: "10px",
              flex: 1,
            }}
          >
            <Stack flexDirection={isMobile ? "column" : "row"} justifyContent={"space-between"}>
              <Typography fontSize="20px" fontWeight="600">
                {t_dashboard("totalKilometers")}
              </Typography>
              <Typography
                fontSize="20px"
                fontWeight="800"
                color={2230 > 1000 ? Colors.score.red : 2230 > 500 ? Colors.score.yellow : Colors.score.green}
              >
                {scores.userScores.distanceByCategory.reduce(
                  (acc, category) => acc + category.totalDistance,

                  0
                )}{" "}
                km
              </Typography>
            </Stack>
            <Stack
              flex={1}
              width={"100%"}
              sx={{ gap: "20px" }}
              flexDirection={isMobile ? "column" : "row"}
              display={"flex"}
              justifyContent={"center"}
            >
              {scores.userScores.distanceByCategory.map((category) => (
                <CategoryScore category={category} />
              ))}
            </Stack>
          </Stack>
        </>
      )}
    </Stack>
  );
};

export default PersonalScore;
