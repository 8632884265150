import { Stack, Typography } from "@mui/material";
import { Colors } from "../constants/colors.constants";
import { useTranslation } from "react-i18next";

interface ReadonlyFieldProps {
  field: string;
  value?: string | null;
  valueElement?: JSX.Element;
  required?: boolean;
  color?: string;
  fieldColor?: string;
}

const ReadonlyField = (props: ReadonlyFieldProps) => {
  const { field, value, valueElement, required, color, fieldColor } = props;
  const { t } = useTranslation();

  return (
    <Stack direction="row" justifyContent="space-between">
      <Typography
        fontWeight={500}
        fontSize={14}
        color={required && !value ? "error" : color ? color : Colors.secondaryText}
      >
        {field}
      </Typography>
      {value ? (
        <Typography fontWeight={600} fontSize={16} color={fieldColor ?? Colors.primaryText}>
          {value}
        </Typography>
      ) : (
        required && (
          <Typography fontWeight={500} fontSize={13} color={"error"}>
            {t("common.toBeCompleted")}
          </Typography>
        )
      )}
      {valueElement && <>{valueElement}</>}
    </Stack>
  );
};

export default ReadonlyField;
