import { Colors } from "src/constants/colors.constants";
import { Stack, Typography, Box, Button } from "@mui/material";
import Logo from "src/assets/images/img_logo.svg";
import { useTranslate } from "src/utils/i18n.utils";
import { useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { sessionService } from "src/store/session";

const ActivateAccount = () => {
  const { t } = useTranslate("activateAccount");
  const [password, setPassword] = useState<string>("");
  const { token } = useParams<{ token: string }>();
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  const handleRegister = async () => {
    if (!token) return;
    if (password.length === 4) {
      sessionService.validateAccount(token, password).subscribe({
        error: (err) => {
          enqueueSnackbar(err.text, err.options);
        },
      });
    } else {
      enqueueSnackbar("Password must be 4 digits", { variant: "error" });
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const value = e.target.value;
    if (/^\d*$/.test(value) && value.length <= 1) {
      const newPassword = password.split("");
      newPassword[index] = value;
      setPassword(newPassword.join(""));

      if (value && index < inputRefs.current.length - 1) {
        inputRefs.current[index + 1]?.focus();
      }
    }
  };

  return (
    <Stack
      justifyContent={"center"}
      alignItems="center"
      gap={2}
      height="100%"
      width={{ xs: "100%", md: "70%" }}
      paddingTop={6}
    >
      <img alt="logo" src={Logo} />
      <Stack width="100%" alignItems="center" gap={2}>
        <Typography fontSize="30px" fontWeight="800">
          {t("title")}
        </Typography>
        <Typography fontSize="18px" fontWeight="400" color={Colors.secondaryText}>
          {t("subtitle")}
        </Typography>
        <Box display="flex" gap={1}>
          {[0, 1, 2, 3].map((index) => (
            <input
              key={index}
              type="text"
              value={password[index] || ""}
              onChange={(e) => handleChange(e, index)}
              ref={(el) => (inputRefs.current[index] = el)}
              style={{
                width: "40px",
                height: "40px",
                textAlign: "center",
                fontSize: "18px",
                border: "1px solid #ccc",
                borderRadius: "4px",
              }}
              maxLength={1}
            />
          ))}
        </Box>
      </Stack>
      <Stack width="100%" alignItems="center">
        <Button variant="contained" color="primary" onClick={handleRegister}>
          {t("validate")}
        </Button>
      </Stack>
    </Stack>
  );
};

export default ActivateAccount;
