import { select } from "@ngneat/elf";

import { categoryChildrenStore } from "./categoryChildren.store";
import { getCategoryChildrenDataSource } from "./categoryChildren.requests";

export class CategoryChildrenQuery {
  store = categoryChildrenStore;

  categoryChildren$ = getCategoryChildrenDataSource.data$();

  filters$ = this.store.pipe(select((state) => state.filters));
  pagination$ = this.store.pipe(select((state) => state.pagination));
}

export const categoryChildrenQuery = new CategoryChildrenQuery();
