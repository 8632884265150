import { createStore, withProps } from "@ngneat/elf";
import { withRequestsStatus } from "@ngneat/elf-requests";
import { SharedCard } from "./sharedCards.models";
import { withEntities } from "@ngneat/elf-entities";

export const sharedCardsStore = createStore(
  { name: "sharedCards" },
  withEntities<SharedCard>(),
  withProps<{ currentCard: SharedCard | null }>({ currentCard: null }),
  withRequestsStatus()
);
