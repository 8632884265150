import { Colors } from "src/constants/colors.constants";
import {Button, Dialog, FormControlLabel, IconButton, Stack, Switch, TextField, Typography} from "@mui/material";
import { useTranslation } from "react-i18next";
import Close from "src/assets/icons/icon_close_dark.svg";
import {useEffect, useState} from "react";
import {sharedCardsServices} from "../../../../store/sharedCards/sharedCards.services";
import {useObservable} from "@ngneat/react-rxjs";
import {sessionQuery} from "../../../../store/session";

interface SettingsSharedCardModal {
  open: boolean;
  handleClose: () => void;
}
const SettingsSharedCardModal = (props: SettingsSharedCardModal) => {
  const { open, handleClose } = props;
  const { t } = useTranslation(undefined, { keyPrefix: "settingsSharedCardModal" });
  const [{ company }] = useObservable(sessionQuery.company$);

  const [settings, setSettings] = useState({
    sharedCardsExpirationTime: 3600,
    releaseSharedCardAfterPayment: false,
  });

  const updateSetting = <K extends keyof typeof settings>(
      key: K,
      value: typeof settings[K]
  ) => {
    setSettings((prevSettings) => ({
      ...prevSettings,
      [key]: value,
    }));
  };

  useEffect(() => {
    console.log("Company:", company);

    if (!company?.id) return;

    const subscription = sharedCardsServices.getCompanySharedCardSettings(company.id).subscribe({
      next: (settingsData) => {
        console.log("Settings received:", settingsData);
        setSettings(settingsData);
      },
      error: (error) => {
        console.error("Error fetching settings:", error);
      },
    });

    return () => subscription.unsubscribe();
  }, [company?.id, open]);

  const handleSave = () => {
    // Save settings
    if (!company?.id) return;
    sharedCardsServices.updateCompanySharedCardSettings(company.id, settings).subscribe({
      next: () => {
        console.log("Settings saved successfully");
        // handleClose();
      },
      error: (error) => {
        console.error("Error saving settings:", error);
      },
    });
    console.log(settings);
  };

  return (
    <Dialog open={open} maxWidth="md" style={{ borderRadius: "8px", zIndex: 2000 }}>
      <Stack direction="column" gap={4} p={4} sx={{ backgroundColor: Colors.background }}>
        <Stack direction="row" justifyContent="space-between">
          <Typography fontSize="30px" fontWeight={800}>
            {t("title")}
          </Typography>
          <IconButton onClick={handleClose}>
            <img alt="" src={Close} />
          </IconButton>
        </Stack>
        <Stack spacing={5}>
          <Typography fontSize={"24px"}>{t("description")}</Typography>
          <FormControlLabel
              control={
                <Switch
                    checked={settings?.releaseSharedCardAfterPayment || false}
                    onChange={(event) =>
                        updateSetting("releaseSharedCardAfterPayment", event.target.checked)
                    }
                />
              }
              label={t("releaseSharedCardAfterPayment")}
          />
          <TextField
              type="number"
              label={t("sharedCardsExpirationTime")}
              value={settings.sharedCardsExpirationTime / 3600}
              onChange={(event) =>
                  updateSetting(
                      "sharedCardsExpirationTime",
                      Number(event.target.value) * 3600
                  )
              }
              inputProps={{ min: 1 }}
              fullWidth
          />
          <Button color="secondary" onClick={handleSave}>
            {t("update")}
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default SettingsSharedCardModal;
