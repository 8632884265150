import { Colors } from "src/constants/colors.constants";
import Smartphone from "src/assets/images/img_smartphone_home.svg";

import { Grid, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";

const Layout = () => {
  const { t } = useTranslation();

  return (
    <Grid container height="100%" direction="row">
      <Grid
        item
        container
        direction="column"
        p={4}
        xs
        color="primary"
        sx={{
          backgroundColor: Colors.primary,
          display: { xs: "none", sm: "flex" },
        }}
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={7}>
          <img alt="" src={Smartphone} />
        </Grid>
        <Grid item xs={2}>
          <Stack gap={2}>
            <Typography fontSize="34px" fontWeight="900" align="center">
              {t("login.title")}
            </Typography>
            <Typography fontSize="22px" fontWeight="500" align="center">
              {t("login.subtitle")}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
      <Grid item container justifyContent="center" xs p={2}>
        <Outlet />
      </Grid>
    </Grid>
  );
};

export default Layout;
