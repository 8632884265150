import { Grid, Stack, Typography } from "@mui/material";
import { DashboardExpense, DashboardExpensesFilters } from "src/store/dashboardExpenses/dashboardExpenses.models";
import { getDashboardExpenses } from "src/store/dashboardExpenses/dashboardExpenses.services";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DashboardExpensesCharts, { barColors } from "./DashboardExpensesCharts";
import DashboardExpensesFiltersModal from "./DashboardExpensesFilters.modal";

export const CURRENT_YEAR_FIRST_JANUARY = new Date(new Date().getFullYear(), 0, 1);

const DashboardExpensesScreen = () => {
  const { t } = useTranslation(undefined, { keyPrefix: "dashboardExpensesScreen" });
  const [dashboardExpenses, setDashboardExpenses] = useState<DashboardExpense[]>();
  const [filters, setFilters] = useState<DashboardExpensesFilters>({
    from: CURRENT_YEAR_FIRST_JANUARY,
    to: new Date(),
  });

  const handleFetchData = async () => {
    const expenses = await getDashboardExpenses(filters);
    setDashboardExpenses(expenses);
  };

  useEffect(() => {
    handleFetchData();
  }, [filters]);

  return (
    <Stack p={3} gap={2} width={"100%"}>
      <Stack width={"100%"} flexWrap={"wrap"} direction={"row"} justifyContent={"space-between"}>
        <Typography fontSize="30px" fontWeight="800">
          {t("title")}
        </Typography>
        <DashboardExpensesFiltersModal filters={filters} setFilters={setFilters} />
      </Stack>
      <Stack direction={"row"} spacing={3}>
        {Object.entries(barColors).map(([color, value]) => (
          <Stack key={value} direction={"row"} alignItems={"center"} spacing={1}>
            <Stack sx={{ backgroundColor: value, width: "20px", height: "20px", borderRadius: "50%" }} />
            <Typography>{t(color)}</Typography>
          </Stack>
        ))}
      </Stack>
      <Grid container rowGap={5} spacing={5}>
        {dashboardExpenses?.map((dashboardExpense) => (
          <Grid key={dashboardExpense.categoryId} item xs={12} md={4}>
            <DashboardExpensesCharts dashboardExpense={dashboardExpense} />
          </Grid>
        ))}
      </Grid>
    </Stack>
  );
};

export default DashboardExpensesScreen;
