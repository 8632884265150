import Header from "src/components/header/Header.component";
import { Colors } from "src/constants/colors.constants";
import { ChevronLeft } from "@mui/icons-material";
import { Button, Card, Input, Stack, Switch, Tooltip, Typography } from "@mui/material";
import { UserRole } from "src/store/users";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  blockAccount,
  getAccountHolders,
  setSuperAdminToHolder,
  updateAccountHolder,
} from "src/store/accountHolders/accountHolders.services";
import { useEffect, useState } from "react";
import { AccountHolders } from "src/store/accountHolders/accountHolders.models";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { enqueueSnackbar } from "notistack";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
const AccountHolderScreen = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [accountHolders, setAccountHolders] = useState<AccountHolders[]>();
  const [isEditAccountHolder, setIsEditAccountHolder] = useState<string>();
  const [companyNames, setCompanyNames] = useState<{ [key: string]: string }>({});

  const fetchAccountHolders = async () => {
    const x = await getAccountHolders();
    if (x) setAccountHolders(x);
  };

  useEffect(() => {
    fetchAccountHolders();
  }, []);

  const handleRowClick = (id: string) => {
    setSuperAdminToHolder(id).then((x) => {
      navigate(`/data/payments`);
      window.location.reload();
    });
  };

  const handleBlockAccount = async (accountHolderId: string) => {
    try {
      await blockAccount(accountHolderId);
    } catch (err) {
      enqueueSnackbar("Une erreur est survenue, veuillez réessayer");
    }
  };

  const handleEditAccountHolder = (accountHolderId: string, name: string) => {
    setIsEditAccountHolder(accountHolderId);
    setCompanyNames((prev) => ({ ...prev, [accountHolderId]: name }));
  };

  const handleSaveAccountHolder = async (companyId: string, accountHolderId: string) => {
    const name = companyNames[accountHolderId];
    updateAccountHolder(companyId, name).then(() => {
      setIsEditAccountHolder(undefined);
      fetchAccountHolders();
    });
  };

  return (
    <Stack alignItems="start" height="100%" gap={2} pt={3}>
      <Stack width="100%">
        <Header role={UserRole.SUPER_ADMIN} />
      </Stack>
      <Button variant="text" startIcon={<ChevronLeft />} onClick={() => navigate(-1)} aria-label="back">
        {t("global.back")}
      </Button>
      <Stack direction="row" justifyContent="space-between" width="100%" flexWrap="wrap">
        <Typography fontSize="30px" fontWeight={800} color={Colors.primaryText}>
          {t("clientAccounts.title")}
        </Typography>
      </Stack>
      {accountHolders && (
        <Stack direction="row" width="100%" p="10px" justifyContent={"space-between"} alignItems={"center"}>
          <Typography color={Colors.secondaryText}>{t("clientAccounts.table.clientEmail")}</Typography>
          <Typography color={Colors.secondaryText}>{t("clientAccounts.table.actions")}</Typography>
        </Stack>
      )}
      {accountHolders ? (
        accountHolders.map((accountHolder) => (
          <Stack
            component={Card}
            width="100%"
            p="20px"
            justifyContent={"space-between"}
            alignItems={"center"}
            key={accountHolder.accountHolderId}
            direction="row"
            sx={{
              ":hover": {
                boxShadow: 10,
              },
            }}
          >
            <Typography flex={1}>{accountHolder.email}</Typography>
            <Stack flex={1} direction="row" alignItems={"center"} spacing={2}>
              <Stack flex={1}>
                {isEditAccountHolder === accountHolder.accountHolderId ? (
                  <Input
                    placeholder="Company name"
                    onChange={(e) =>
                      setCompanyNames((prev) => ({ ...prev, [accountHolder.accountHolderId]: e.target.value }))
                    }
                    value={companyNames[accountHolder.accountHolderId] || ""}
                    defaultValue={accountHolder.name}
                  />
                ) : (
                  <Typography>{accountHolder.name ?? "Aucun nom de société"}</Typography>
                )}
              </Stack>
              <Stack flex={1} justifyContent={"flex-end"}>
                {isEditAccountHolder === accountHolder.accountHolderId ? (
                  <SaveOutlinedIcon
                    onClick={() => handleSaveAccountHolder(accountHolder.companyId, accountHolder.accountHolderId)}
                    style={{ cursor: "pointer" }}
                  />
                ) : (
                  <EditOutlinedIcon
                    onClick={() => handleEditAccountHolder(accountHolder.accountHolderId, accountHolder.name)}
                    style={{ cursor: "pointer" }}
                  />
                )}
              </Stack>
            </Stack>

            <Stack flex={1} direction="row" justifyContent={"flex-end"} alignItems={"center"} spacing={2}>
              <Tooltip title={t("clientAccounts.table.seeAccount")}>
                <Stack
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    handleRowClick(accountHolder.accountHolderId);
                  }}
                >
                  <VisibilityOutlinedIcon color="primary" />
                </Stack>
              </Tooltip>
              <Tooltip title={t("clientAccounts.table.blockAccount")}>
                <Switch
                  defaultChecked={accountHolder.isActive}
                  onChange={() => handleBlockAccount(accountHolder.accountHolderId)}
                />
              </Tooltip>
            </Stack>
          </Stack>
        ))
      ) : (
        <Typography> {t("clientAccounts.noAccounts")}</Typography>
      )}
    </Stack>
  );
};

export default AccountHolderScreen;
