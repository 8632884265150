import { useCallback, useEffect, useState } from "react";
import { Button, IconButton, Stack, Typography, styled } from "@mui/material";
import { DataGrid, GridColDef, GridPaginationModel, GridSortModel } from "@mui/x-data-grid";
import { ChevronLeft, ControlPoint } from "@mui/icons-material";
import { Colors } from "../../../../constants/colors.constants";
import { useEffectFn } from "@ngneat/effects-hooks";
import { enqueueSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  CategoryChildren,
  categoryChildrenQuery,
  categoryChildrenServices,
  searchCategoryChildrenEffect,
} from "../../../../store/categoryChildren";
import { useObservable } from "@ngneat/react-rxjs";
import { UserRole } from "../../../../store/users";

import Edit from "../../../../assets/icons/icon_edit_dark.svg";
import Delete from "../../../../assets/icons/icon_delete_color.svg";
import ConfirmDialog from "../../../../components/Confirm.component";
// import AddCategory from './AddCategory.modale';
import Header from "../../../../components/header/Header.component";
import AddCategoryChild from "./AddCategoryChild.modal";

const CustomDataGrid = styled(DataGrid)({
  border: "none !important",
  width: "100%",
  flexDirection: "column-reverse",
  "& .MuiDataGrid-row": {
    backgroundColor: Colors.white,
    borderRadius: "8px",
    marginTop: "4px",
    marginBottom: "4px",
    border: 0,
    maxHeight: "54px !important",
    minHeight: "54px !important",
  },
  "& .MuiDataGrid-columnHeaders": {
    border: 0,
  },
  "& .MuiDataGrid-columnHeaders:focus": {
    outline: "none",
  },
  "& .MuiDataGrid-cell": {
    border: 0,
    fontSize: "18px",
    fontWeight: 600,
    color: Colors.primaryText,
  },
  "& .MuiDataGrid-withBorderColor": {
    borderColor: "transparent",
  },
  "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus, .MuiDataGridCell--withRenderer":
    {
      outline: "none !important",
    },
  "& .MuiTablePagination-root": {
    position: "absolute",
    top: "52px",
    right: 0,
  },
});

const HeaderTypography = styled(Typography)({
  fontSize: "12px",
  color: Colors.secondaryText,
  fontWeight: "600",
  textTransform: "uppercase",
});

const ChildrenCategoryScreen = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [modalOpen, setModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [childCategoryToDelete, setChildCategoryToDelete] = useState<CategoryChildren | undefined>();
  const [selectedChild, setSelectedChild] = useState<CategoryChildren | undefined>();
  const [filters] = useObservable(categoryChildrenQuery.filters$);
  const [pagination] = useObservable(categoryChildrenQuery.pagination$);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const [rowCountState, setRowCountState] = useState(pagination?.totalCount || 0);
  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      pagination?.totalCount !== undefined ? pagination?.totalCount : prevRowCountState
    );
  }, [pagination?.totalCount, setRowCountState]);

  const [{ categoryChildren }] = useObservable(categoryChildrenQuery.categoryChildren$);

  const searchCategoryChildren = useEffectFn(searchCategoryChildrenEffect);

  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    setPaginationModel({ page: 0, pageSize: 10 });
    categoryChildrenServices.setFilters({
      page: 1,
      orderByDirection: sortModel[0]?.sort || undefined,
      orderBy: sortModel[0]?.field || undefined,
    });
  }, []);

  const handleDeleteCategory = () => {
    categoryChildrenServices.deleteCategoryChild(childCategoryToDelete?.id ?? "").subscribe({
      next: () => {
        enqueueSnackbar(t("category.delete.success"), { variant: "success" });
        setDeleteModalOpen(false);
      },
      error: (err) => enqueueSnackbar(err.text, err.options),
    });
    setChildCategoryToDelete(undefined);
  };

  useEffect(() => {
    selectedChild && setModalOpen(true);
  }, [selectedChild]);

  useEffect(() => {
    childCategoryToDelete && setDeleteModalOpen(true);
  }, [childCategoryToDelete]);

  useEffect(() => {
    searchCategoryChildren({ ...filters, page: paginationModel.page + 1 });
  }, [searchCategoryChildren, filters, paginationModel]);

  const columns: GridColDef[] = [
    {
      field: "name",
      renderHeader: () => <HeaderTypography>{t("categories.table.categoryName")}</HeaderTypography>,
      flex: 1,
      minWidth: 200,
      renderCell(params) {
        return <Typography>{params.row.serviceCategory.name}</Typography>;
      },
    },
    {
      field: "associatedAccount",
      sortable: false,
      renderHeader: () => <HeaderTypography>{t("categories.table.associatedAccount")}</HeaderTypography>,
      minWidth: 150,
      flex: 1,
      renderCell(params) {
        return <Typography>{params.row.associatedAccount}</Typography>;
      },
    },
    {
      field: "edit",
      headerName: "",
      width: 45,
      renderCell(params) {
        return (
          <IconButton
            onClick={() => {
              setSelectedChild(params.row);
            }}
          >
            <img alt="" src={Edit} />
          </IconButton>
        );
      },
    },
    {
      field: "delete",
      headerName: "",
      width: 45,
      renderCell(params) {
        return (
          <IconButton
            onClick={() => {
              setChildCategoryToDelete(params.row);
            }}
          >
            <img src={Delete} alt="" />
          </IconButton>
        );
      },
    },
  ];

  return (
    <>
      {modalOpen && (
        <AddCategoryChild
          categoryChild={selectedChild}
          affectedCategories={categoryChildren.map((it) => it.serviceCategory.id)}
          handleClose={() => {
            setModalOpen(false);
            setSelectedChild(undefined);
          }}
        />
      )}
      {deleteModalOpen && (
        <ConfirmDialog
          title={t("categories.delete")}
          confirm={t("global.validate")}
          cancel={t("global.cancel")}
          handleClose={() => {
            setChildCategoryToDelete(undefined);
            setDeleteModalOpen(false);
          }}
          handleValidate={() => {
            handleDeleteCategory();
          }}
        />
      )}
      <Stack alignItems="start" height="100%" gap={2} pt={3}>
        <Stack width="100%">
          <Header role={UserRole.ADMIN_COMPANY} />
        </Stack>
        <Button variant="text" startIcon={<ChevronLeft />} onClick={() => navigate(-1)} aria-label="back">
          {t("global.back")}
        </Button>
        <Stack direction="row" justifyContent="space-between" width="100%" flexWrap="wrap">
          <Typography fontSize="30px" fontWeight={800} color={Colors.primaryText}>
            Catégories
          </Typography>
          <Stack direction="row" gap={2}>
            <Button
              style={{ height: "52px" }}
              variant="contained"
              color="secondary"
              onClick={() => setModalOpen(true)}
              startIcon={<ControlPoint />}
            >
              Ajouter
            </Button>
          </Stack>
        </Stack>
        <CustomDataGrid
          rows={categoryChildren}
          pagination
          disableColumnMenu
          disableColumnSelector
          disableRowSelectionOnClick
          disableDensitySelector
          disableColumnFilter
          rowSelection={false}
          filterMode="server"
          disableVirtualization
          paginationMode="server"
          paginationModel={paginationModel}
          rowCount={rowCountState}
          onPaginationModelChange={setPaginationModel}
          sortingMode="server"
          onSortModelChange={handleSortModelChange}
          columns={columns}
          pageSizeOptions={[]}
          slotProps={{
            pagination: {
              labelRowsPerPage: "test",
            },
          }}
        />
      </Stack>
    </>
  );
};

export default ChildrenCategoryScreen;
