import { object } from "yup";

import * as YupUtils from "../../../../utils/yup.utils";
import { CategoryChildren } from "../../../../store/categoryChildren";

export const addCategoryChildValidation = (categoryChild: Partial<CategoryChildren>) => {

  try {
    const schema = object().shape({
      serviceCategory: object().required(),
    });
    schema.validateSync(categoryChild, { abortEarly: false });
    return {};
  } catch (err) {
    return YupUtils.transformError(err as any);
  }
};
