import { createRequestDataSource } from "@ngneat/elf-requests";
import { selectAllEntities } from "@ngneat/elf-entities";
import { sharedCardsStore } from "./sharedCards.store";

export const getSharedCardsDataSource = createRequestDataSource({
  store: sharedCardsStore,
  data$: () => sharedCardsStore.pipe(selectAllEntities()),
  requestKey: "getSharedCards",
  dataKey: "sharedCards",
});
