import { Card, CardStatus, CardType } from "@store/transaction";
import { Colors } from "../../constants/colors.constants";
import { Company } from "@store/companies/company.model";

export interface User {
  id: string;
  accountHolderId?: string;
  firstName: string;
  cards: Card[];
  lastName: string;
  phoneNumber: string;
  role: UserRole;
  email: string;
  accountMembershipId: string;
  accountMembershipStatus: MembershipStatus;
  isActive: boolean;
  company: Company;
}

export interface UserCard {
  userId: string;
  userFirstName: string;
  userLastName: string;
  cardId: string;
  cardName: string;
  cardStatus: CardStatus;
  cardType: CardType;
  cardGroup: string;
  accountMembershipStatus: MembershipStatus;
}

export enum UserRole {
  SUPER_ADMIN = "SUPER_ADMIN",
  ADMIN_COMPANY = "ADMIN",
  EMPLOYEE = "EMPLOYEE",
}

export namespace UserRole {
  export const label = (role: UserRole): string => {
    switch (role) {
      case UserRole.SUPER_ADMIN:
        return "Super Admin";
      case UserRole.ADMIN_COMPANY:
        return "Gestionnaire";
      case UserRole.EMPLOYEE:
        return "Employé(e)";
    }
  };
}

export interface GETUsers {
  users: User[];
  totalCount: number;
  currentPage: number;
  totalPageCount: number;
}

export interface UserFilter {
  orderByDirection?: "asc" | "desc";
  page: number;
  orderBy?: string;
  searchText?: string;
  hasNoAccountHolderId?: boolean;
  companyId?: string;
  pageSize?: number;
}

export enum MembershipStatus {
  BINDING_USER_ERROR = "BINDING_USER_ERROR", // désactivé
  CONSENT_PENDING = "CONSENT_PENDING", //en attente
  DISABLED = "DISABLED", // désactivé
  ENABLED = "ENABLED", //actif
  INVITATION_SENT = "INVITATION_SENT", // en attente
  SUSPENDED = "SUSPENDED", //désactivé
  UNKNOWN = "UNKNOWN", // désactivé
}

export namespace MembershipStatus {
  export const label = (status: MembershipStatus): string => {
    switch (status) {
      case MembershipStatus.BINDING_USER_ERROR:
        return "Désactivé";
      case MembershipStatus.CONSENT_PENDING:
        return "En attente";
      case MembershipStatus.DISABLED:
        return "Désactivé";
      case MembershipStatus.ENABLED:
        return "Actif";
      case MembershipStatus.INVITATION_SENT:
        return "En attente";
      case MembershipStatus.SUSPENDED:
        return "Désactivé";
      case MembershipStatus.UNKNOWN:
        return "Désactivé";
    }
  };

  export const color = (status: MembershipStatus): string => {
    switch (status) {
      case MembershipStatus.CONSENT_PENDING:
        return Colors.yellow;
      case MembershipStatus.ENABLED:
        return Colors.green;
      default:
        return Colors.warning;
    }
  };
}

export interface UploadResponse {
  key: string;
  url: string;
}
