import APIAxios from "src/api/axios.api";
import { useEffect } from "react";
import { enqueueSnackbar } from "notistack";
import { useTranslate } from "src/utils/i18n.utils";

const SwanRedirectScreen = () => {
  const { t } = useTranslate("global");
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const queryString = params.toString();

    APIAxios.get(`/swan/redirect?${queryString}`)
      .then((response) => response)
      .then((data) => {
        console.log(data);
        window.close();
      })
      .catch((error) => {
        enqueueSnackbar(t("errors.swanRedirect"), { variant: "error" });
      });
  }, [t]);

  return <div style={{ fontSize: "2em", textAlign: "center", marginTop: "20%" }}>{t("loading")}</div>;
};

export default SwanRedirectScreen;
