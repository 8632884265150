import {from, catchError, map, tap, Observable} from "rxjs";
import { AxiosError, AxiosResponse } from "axios";
import APIAxios, { APIRoutes } from "../../api/axios.api";
import SnackError from "../../utils/errors.utils";
import { setEntities } from "@ngneat/elf-entities";
import { getSharedCardsDataSource } from "./sharedCards.requests";
import { sharedCardsStore } from "./sharedCards.store";
import {CompanySharedCardsSettings, SharedCard} from "./sharedCards.models";
import { setProps } from "@ngneat/elf";
import {CompanyTax, getTaxesDataSource} from "@store/taxes";

export class SharedCardsServices {
  store = sharedCardsStore;

  resetStore = () => this.store.reset();

  getCurrentCard = () => {
    return from(
      APIAxios({
        ...APIRoutes.GETCurrentCard(),
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<any>) => response.data),
      tap((sharedCards) => {
        this.store.update(setProps({ currentCard: sharedCards }), getSharedCardsDataSource.setSuccess());
      }),
      getSharedCardsDataSource.trackRequestStatus()
    );
  };

  fetchAllCards = () => {
    return from(
      APIAxios({
        ...APIRoutes.GETSharedCards(),
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<any>) => response.data),
      tap((sharedCards) => {
        this.store.update(setEntities(sharedCards), getSharedCardsDataSource.setSuccess());
      }),
      getSharedCardsDataSource.trackRequestStatus()
    );
  };

  selectCardById = (cardId: string) => {
    return from(
      APIAxios({
        ...APIRoutes.POSTSelectCard(cardId),
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<SharedCard>) => response.data),
      tap((sharedCard) => {
        this.store.update(setProps({ currentCard: sharedCard }), getSharedCardsDataSource.setSuccess());
      })
    );
  };

  deleteCardShared = (cardId: string) => {
    return from(
      APIAxios({
        ...APIRoutes.DELETECardShared(cardId),
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<SharedCard>) => response.data),
      tap((sharedCard) => {
        this.store.update(setProps({ currentCard: null }), getSharedCardsDataSource.setSuccess());
      })
    );
  };

  getCompanySharedCardSettings = (companyId: string): Observable<CompanySharedCardsSettings> => {
    return from(APIAxios({...APIRoutes.GETCompanySharedCardsSettings(companyId)})).pipe(
        catchError((err: AxiosError) => {
          throw new SnackError((err.response as any)?.data?.message, 'error')
        }),
        map((response: AxiosResponse<CompanySharedCardsSettings>) => {
          return response.data;
        }),
    )
  }

    updateCompanySharedCardSettings = (companyId: string, settings: Partial<CompanySharedCardsSettings>): Observable<CompanySharedCardsSettings> => {
        return from(APIAxios({...APIRoutes.PUTCompanySharedCardsSettings(companyId), data: settings})).pipe(
            catchError((err: AxiosError) => {
            throw new SnackError((err.response as any)?.data?.message, 'error')
            }),
            map((response: AxiosResponse<CompanySharedCardsSettings>) => {
            return response.data;
            }),
        )
    }
}

export const sharedCardsServices = new SharedCardsServices();
