import { useEffect, useState } from "react";
import { Button, Stack, Typography, Autocomplete, TextField } from "@mui/material";
import { useObservable } from "@ngneat/react-rxjs";
import { sharedCardsServices } from "src/store/sharedCards/sharedCards.services";
import { enqueueSnackbar } from "notistack";
import dayjs from "dayjs";
import { sharedCardsQuery } from "src/store/sharedCards/sharedCards.query";
import { useTranslation } from "react-i18next";
import ConfirmDialog from "src/components/Confirm.component";

const SharedCardSection = () => {
  const [useSharedCard, setUseSharedCard] = useState(false);
  const [sharedCardModalOpen, setSharedCardModalOpen] = useState(false);
  const [selectedCard, setSelectedCard] = useState<{ id: string; name: string } | null>(null);
  const [currentCard] = useObservable(sharedCardsQuery.currentCard$);
  const [{ sharedCards }] = useObservable(sharedCardsQuery.sharedCards$());
  const { t } = useTranslation();

  useEffect(() => {
    sharedCardsServices.fetchAllCards().subscribe({
      error: (err) => enqueueSnackbar(err.text, err.options),
    });
    sharedCardsServices.getCurrentCard().subscribe({
      error: (err) => enqueueSnackbar(err.text, err.options),
    });
  }, []);

  const handleSharedCardValidation = () => {
    if (selectedCard) {
      sharedCardsServices.selectCardById(selectedCard.id).subscribe({
        next: (data) => {
          setSharedCardModalOpen(false);
          setUseSharedCard(false);
          enqueueSnackbar(t("sharedCard.success", { cardName: data?.name }), { variant: "success" });
        },
        error: (err) => enqueueSnackbar(err.text, err.options),
      });
    }
  };

  const handleDeleteSharedCard = () => {
    if (currentCard) {
      sharedCardsServices.deleteCardShared(currentCard.id).subscribe({
        next: () => {
          setUseSharedCard(false);
          setSelectedCard(null);
          enqueueSnackbar(t("sharedCard.deleteSuccess"), { variant: "success" });
        },
        error: (err) => enqueueSnackbar(err.text, err.options),
      });
    }
  };

  const CurrentCardDisplay = () => (
    <Stack width="100%" direction="row" gap={20} alignItems="center" justifyContent="space-between">
      <Stack gap={1}>
        <Typography sx={{ fontSize: "24px", fontWeight: "800" }}>
          {t("sharedCard.currentCard", {
            cardName: currentCard?.name,
            date: dayjs(currentCard?.bookedAt).add(1, "hour").format("HH[h]mm"),
          })}
        </Typography>
        <Typography sx={{ fontWeight: 500 }}>{t("sharedCard.usageRestriction")}</Typography>
      </Stack>
      <Button variant="contained" color="error" onClick={handleDeleteSharedCard}>
        {t("sharedCard.delete")}
      </Button>
    </Stack>
  );

  const SharedCardSelection = () => (
    <Stack direction="row" gap={2}>
      <Autocomplete
        sx={{ flex: 1 }}
        value={
          selectedCard
            ? { label: sharedCards.find((card) => card.id === selectedCard.id)?.name, value: selectedCard.id }
            : null
        }
        options={sharedCards.map((card) => ({ label: card.name, value: card.id }))}
        onChange={(_, value) => setSelectedCard({ id: value?.value ?? "", name: value?.label ?? "" })}
        renderInput={(params) => <TextField {...params} label={t("sharedCard.label")} />}
      />
      <Button variant="contained" disabled={!selectedCard} color="success" onClick={() => setSharedCardModalOpen(true)}>
        {t("global.validate")}
      </Button>
      <Button variant="contained" color="error" onClick={() => setUseSharedCard(false)}>
        {t("global.cancel")}
      </Button>
    </Stack>
  );

  return (
    <Stack width="100%" sx={{ border: "1px solid #E0E0E0", borderRadius: "12px", p: 2 }}>
      {!useSharedCard ? (
        <Stack sx={{ alignSelf: "center" }} direction="row" gap={2}>
          {!currentCard ? (
            <Stack alignItems="center" gap={1}>
              <Button variant="contained" color="error" onClick={() => setUseSharedCard(true)}>
                {t("sharedCard.use")}
              </Button>
              <Typography sx={{ textAlign: "center", fontWeight: 500 }}>{t("sharedCard.useDescription")}</Typography>
            </Stack>
          ) : (
            <CurrentCardDisplay />
          )}
        </Stack>
      ) : (
        <SharedCardSelection />
      )}
      {sharedCardModalOpen && (
        <ConfirmDialog
          title={t("sharedCard.validate")}
          confirm={t("global.validate")}
          description={t("sharedCard.validateDescription", {
            cardName: selectedCard?.name,
          })}
          cancel={t("global.cancel")}
          handleClose={() => {
            setSharedCardModalOpen(false);
            setUseSharedCard(false);
            setSelectedCard(null);
          }}
          handleValidate={handleSharedCardValidation}
        />
      )}
    </Stack>
  );
};

export default SharedCardSection;
