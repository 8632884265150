import { sessionStore } from "./session.store";
import { catchError, from, map, Observable, tap } from "rxjs";
import { Buffer } from "buffer";
import { AxiosError, AxiosResponse } from "axios";
import { getCompanyDataSource, getUserDataSource } from "./session.requests";
import { AuthData, AuthResponse, Company } from "./session.model";
import APIAxios, { APIRoutes } from "../../api/axios.api";
import SnackError from "../../utils/errors.utils";
import { User } from "../users/user.model";

export class SessionService {
  store = sessionStore;

  setAccessToken = (accessToken: string) => {
    localStorage.setItem("token", Buffer.from(accessToken).toString("base64"));
  };

  validateAccount = (token: string, password: string): Observable<AuthResponse> => {
    return from(APIAxios({ ...APIRoutes.POSTActivateAccount(token), data: { password } })).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<AuthResponse>) => {
        return response.data;
      }),
      tap(async (authResponse) => {
        this.store.update(
          (state) => ({
            ...state,
            ...authResponse,
          }),
          getUserDataSource.setSuccess()
        );
        this.setAccessToken(authResponse.accessToken);
      })
    );
  };

  login = (data: AuthData): Observable<AuthResponse> => {
    return from(APIAxios({ ...APIRoutes.POSTLogin(), data })).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<AuthResponse>) => {
        return response.data;
      }),
      tap(async (authResponse) => {
        this.store.update(
          (state) => ({
            ...state,
            ...authResponse,
          }),
          getUserDataSource.setSuccess()
        );
        this.setAccessToken(authResponse.accessToken);
      })
    );
  };

  getCompany = (): Observable<Company> => {
    return from(APIAxios({ ...APIRoutes.GETCompany() })).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<Company>) => {
        return response.data;
      }),
      tap((company) => {
        this.store.update((state) => ({ ...state, company }));
        getCompanyDataSource.setSuccess();
      })
    );
  };

  fetchCurrentUser = (): Observable<User> => {
    return from(APIAxios(APIRoutes.GETUser())).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<User>) => {
        return response.data;
      }),
      tap(async (user) => {
        this.store.update(
          (state) => ({
            ...state,
            user,
          }),
          getUserDataSource.setSuccess()
        );
      }),
      getUserDataSource.trackRequestStatus()
    );
  };

  getForgotPassword = (email: string): Observable<AxiosResponse> => {
    return from(APIAxios(APIRoutes.GETPasswordRecovery(email))).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<AxiosResponse>) => {
        return response.data;
      })
    );
  };

  updateCompany = (data: Partial<Company>): Observable<Company> => {
    return from(APIAxios({ ...APIRoutes.PUTCompany(data.id ?? ""), data: data })).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<Company>) => {
        return response.data;
      }),
      tap((company) => {
        this.store.update((state) => ({ ...state, company }));
      })
    );
  };

  uploadCGUCompany = (file: string, companyId: string): Observable<Company> => {
    return from(APIAxios({ ...APIRoutes.PUTCGUCompany(companyId), data: { url: file } })).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<Company>) => {
        return response.data;
      }),
      tap((company) => {
        this.store.update((state) => ({
          ...state,
          company,
        }));
      })
    );
  };

  updateHelpDocumentForCompany = (file: string, companyId: string): Observable<Company> => {
    return from(
      APIAxios({
        ...APIRoutes.PUTHelpDocumentForCompany(companyId),
        data: { url: file },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<Company>) => {
        return response.data;
      }),
      tap((company) => {
        this.store.update((state) => ({
          ...state,
          company,
        }));
      })
    );
  };

  uploadLogoCompany = (file: string, companyId: string): Observable<Company> => {
    return from(APIAxios({ ...APIRoutes.PUTLogoCompany(companyId), data: { url: file } })).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<Company>) => {
        return response.data;
      }),
      tap((company) => {
        this.store.update((state) => ({
          ...state,
          company,
        }));
      })
    );
  };

  logout = async () => {
    await localStorage.clear();
    window.location.reload();
  };
}

export const sessionService = new SessionService();
