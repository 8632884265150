import { setEntities } from "@ngneat/elf-entities";
import { AxiosResponse } from "axios";
import { catchError, from, map, Observable, tap } from "rxjs";
import APIAxios, { APIRoutes } from "src/api/axios.api";
import SnackError from "src/utils/errors.utils";
import { ScoreDto } from "./score.models";
import { GETScoreDataSource } from "./score.requests";
import { scoreStore } from "./score.store";
import { DashboardExpensesFilters } from "@store/dashboardExpenses/dashboardExpenses.models";

export class ScoreServices {
  store = scoreStore;

  resetStore = () => this.store.reset();

  getScore = (filters: DashboardExpensesFilters): Observable<ScoreDto> => {
    return from(APIAxios({ ...APIRoutes.GETScore(filters.from, filters.to) })).pipe(
      catchError((err) => {
        throw new SnackError(err.message, "error");
      }),
      map((response: AxiosResponse<ScoreDto>) => {
        return response.data;
      }),
      tap((score) => {
        this.store.update(setEntities([score]), GETScoreDataSource.setSuccess());
      }),
      GETScoreDataSource.trackRequestStatus()
    );
  };
}

export const scoreService = new ScoreServices();
