import { useCallback, useEffect, useState } from "react";
import { Button, IconButton, Stack, Typography, styled } from "@mui/material";
import { DataGrid, GridColDef, GridSortModel } from "@mui/x-data-grid";
import { ChevronLeft, ControlPoint } from "@mui/icons-material";
import { Colors } from "../../../constants/colors.constants";
import { enqueueSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useEffectFn } from "@ngneat/effects-hooks";
import { MIDException, midExceptionsServices, searchMIDEffect, midExceptionsQuery } from "../../../store/mid";
import { useObservable } from "@ngneat/react-rxjs";
import { UserRole } from "../../../store/users";
import APIAxios, { APIRoutes } from "../../../api/axios.api";
import { Category } from "../../../store/serviceCategories";

import Edit from "../../../assets/icons/icon_edit_dark.svg";
import Delete from "../../../assets/icons/icon_delete_color.svg";

import BasicSearchbar from "../../../components/inputs/BasicSearchBar.component";
import AddMIDExceptionModal from "./AddMIDException.modal";
import ConfirmDialog from "../../../components/Confirm.component";
import Header from "../../../components/header/Header.component";
import AsyncSelectWithSearchComponent, {
  SelectOption,
} from "../../../components/inputs/AsyncSelectWithSearchComponent.component";
import dayjs from "dayjs";

const CustomDataGrid = styled(DataGrid)({
  border: "none !important",
  width: "100%",
  flexDirection: "column-reverse",
  "& .MuiDataGrid-row": {
    backgroundColor: Colors.white,
    borderRadius: "8px",
    marginTop: "4px",
    marginBottom: "4px",
    border: 0,
    maxHeight: "54px !important",
    minHeight: "54px !important",
    fontSize: "18px",
    fontWeight: "600",
  },
  "& .MuiDataGrid-row:hover": {
    backgroundColor: "white",
  },
  "& .MuiDataGrid-columnHeaders": {
    border: 0,
  },
  "& .MuiDataGrid-columnHeaders:focus": {
    outline: "none",
  },
  "& .MuiDataGrid-cell": {
    border: 0,
    fontSize: "18px",
    fontWeight: 600,
    color: Colors.primaryText,
  },
  "& .MuiDataGrid-withBorderColor": {
    borderColor: "transparent",
  },
  "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus, .MuiDataGridCell--withRenderer":
    {
      outline: "none !important",
    },
  "& .MuiTablePagination-root": {
    position: "absolute",
    top: "52px",
    right: 0,
  },
});

const HeaderTypography = styled(Typography)({
  fontSize: "12px",
  color: Colors.secondaryText,
  fontWeight: "600",
  textTransform: "uppercase",
});

const MIDExceptionsScreen = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const searchMid = useEffectFn(searchMIDEffect);

  const [filters] = useObservable(midExceptionsQuery.filters$);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedMid, setSelectedMid] = useState<MIDException | undefined>(undefined);
  const [midToDelete, setMidToDelete] = useState<MIDException | undefined>(undefined);
  const [pagination] = useObservable(midExceptionsQuery.pagination$);
  const [rowCountState, setRowCountState] = useState(pagination?.totalCount || 0);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const [{ mid, error: midError }] = useObservable(midExceptionsQuery.midExceptions$);

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      pagination?.totalCount !== undefined ? pagination?.totalCount : prevRowCountState
    );
  }, [pagination?.totalCount, setRowCountState]);

  useEffect(() => {
    selectedMid && setModalOpen(true);
  }, [selectedMid]);

  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    setPaginationModel({ page: 0, pageSize: 10 });
    midExceptionsServices.setFilters({
      page: 1,
      orderByDirection: sortModel[0]?.sort || undefined,
      orderBy: sortModel[0]?.field || undefined,
    });
  }, []);

  const getCategories = async (q?: string): Promise<SelectOption[]> => {
    return await APIAxios({
      ...APIRoutes.GETCategories({ page: 1, orderBy: undefined, orderByDirection: undefined, searchText: q }),
    }).then((res) => {
      if (res.data) {
        return res.data.serviceCategories.map((it: Category) => ({ label: it.name, value: it.id, data: it }));
      }
    });
  };

  const handleDeleteMid = () => {
    midExceptionsServices.deleteMidException(midToDelete?.id ?? "").subscribe({
      next: () => {
        enqueueSnackbar(t("mid.delete.success", { variant: "success" }));
        setMidToDelete(undefined);
      },
      error: (err) => enqueueSnackbar(err.text, err.options),
    });
  };

  const handleUpdateServiceCategory = (midExceptionId: string, category: Category) => {
    midExceptionsServices
      .updateMidException({
        id: midExceptionId,
        serviceCategory: category,
      })
      .subscribe({
        error: (err) => enqueueSnackbar(err.text, err.options),
      });
  };

  useEffect(() => {
    if (midError) enqueueSnackbar((midError as any)?.text, (midError as any)?.options);
  }, [midError]);

  useEffect(() => {
    searchMid({ ...filters, page: paginationModel.page + 1 });
  }, [filters, searchMid, paginationModel]);
  const columns: GridColDef[] = [
    {
      field: "name",
      renderHeader: () => <HeaderTypography>{t("mid.table.name")}</HeaderTypography>,
      flex: 1,
      minWidth: 30,
      renderCell(params) {
        return <Typography>{params.row.name}</Typography>;
      },
    },
    {
      field: "merchantId",
      renderHeader: () => <HeaderTypography>{t("mid.table.merchantId")}</HeaderTypography>,
      flex: 1,
      minWidth: 30,
      renderCell(params) {
        return <Typography>{params.row.mid}</Typography>;
      },
    },
    {
      field: "category",
      renderHeader: () => <HeaderTypography>{t("mid.table.category")}</HeaderTypography>,
      flex: 1,
      minWidth: 200,
      renderCell(params) {
        return (
          <AsyncSelectWithSearchComponent
            getOptions={(v) => getCategories(v)}
            placeholder={t("mid.add.category") || ""}
            value={
              params.row.serviceCategory
                ? {
                    value: params.row.serviceCategory.id,
                    data: params.row?.serviceCategory,
                    label: params.row?.serviceCategory.name,
                  }
                : undefined
            }
            handleChange={(value) => {
              handleUpdateServiceCategory(params.row.id, value?.data);
            }}
          />
        );
      },
    },
    {
      field: "updatedBy",
      renderHeader: () => <HeaderTypography>{t("mid.table.updatedBy")}</HeaderTypography>,
      flex: 1,
      minWidth: 30,
      renderCell(params) {
        return (
          <Typography>
            {(params.row.updatedBy?.lastName || "") + " " + (params.row.updatedBy?.firstName || "")}
          </Typography>
        );
      },
    },
    {
      field: "updatedAt",
      renderHeader: () => <HeaderTypography>{t("mid.table.updatedAt")}</HeaderTypography>,
      flex: 1,
      minWidth: 30,
      renderCell(params) {
        return <Typography>{params.row.updatedAt ? dayjs(params.row.updatedAt).format("DD/MM/YYYY") : ""}</Typography>;
      },
    },
    {
      field: "edit",
      headerName: "",
      width: 45,
      renderCell(params) {
        return (
          <IconButton
            onClick={() => {
              setSelectedMid(params.row);
            }}
          >
            <img alt="" src={Edit} />
          </IconButton>
        );
      },
    },
    {
      field: "delete",
      headerName: "",
      width: 45,
      renderCell(params) {
        return (
          <IconButton
            onClick={() => {
              setMidToDelete(params.row);
            }}
          >
            <img src={Delete} alt="" />
          </IconButton>
        );
      },
    },
  ];

  return (
    <>
      {modalOpen && (
        <AddMIDExceptionModal
          mid={selectedMid}
          handleClose={() => {
            setModalOpen(false);
            setSelectedMid(undefined);
          }}
        />
      )}
      {midToDelete && (
        <ConfirmDialog
          title={t("mid.delete.title")}
          confirm={t("global.validate")}
          cancel={t("global.cancel")}
          description={t("mid.delete.description") as string}
          handleValidate={() => {
            handleDeleteMid();
          }}
          handleClose={() => {
            setMidToDelete(undefined);
          }}
        />
      )}
      <Stack alignItems="start" height="100%" gap={2} pt={3}>
        <Stack width="100%">
          <Header role={UserRole.SUPER_ADMIN} />
        </Stack>
        <Button variant="text" startIcon={<ChevronLeft />} onClick={() => navigate(-1)} aria-label="back">
          {t("global.back")}
        </Button>
        <Stack direction="row" gap={2} width="100%" justifyContent="space-between" flexWrap="wrap">
          <Typography fontSize="30px" fontWeight="800">
            {t("mid.title")}
          </Typography>
          <Stack gap={2} direction="row">
            <BasicSearchbar
              placeholder={t("global.search")}
              onChange={(value) => {
                setPaginationModel({ page: 0, pageSize: 10 });
                midExceptionsServices.setFilters({ searchText: value });
              }}
            />
            <Button
              style={{ height: "52px" }}
              variant="contained"
              color="secondary"
              onClick={() => setModalOpen(true)}
              startIcon={<ControlPoint />}
            >
              {t("global.add")}
            </Button>
          </Stack>
        </Stack>
        {mid && (
          <CustomDataGrid
            rows={mid}
            pagination
            disableColumnMenu
            rowCount={rowCountState}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            paginationMode="server"
            disableColumnSelector
            disableRowSelectionOnClick
            disableDensitySelector
            disableColumnFilter
            rowSelection={false}
            filterMode="server"
            disableVirtualization
            onSortModelChange={handleSortModelChange}
            columns={columns}
            pageSizeOptions={[]}
            slotProps={{
              pagination: {
                labelRowsPerPage: "",
              },
            }}
          />
        )}
      </Stack>
    </>
  );
};

export default MIDExceptionsScreen;
