import { useLocation } from "react-router-dom";
import CardDark from "../assets/icons/icon_creditcard_dark.svg";
import CardLight from "../assets/icons/icon_creditcard_light.svg";
import LeafDark from "../assets/icons/icon_leaf_black.svg";
import LeafLight from "../assets/icons/icon_leaf_white.svg";
import EuroBlackIcon from "../assets/icons/euro_black.svg";
import EuroWhiteIcon from "../assets/icons/euro_white.svg";

export enum Pages {
  DASHBOARD = "dashboard",
  ACCOUNT_HOLDER = "account-holder",
  CATEGORIES = "categories",
  CHILDREN_CATEGORY = "categories-company-settings",
  ADD_CATEGORY = "add",
  MID = "mid",
  CGU_LOGO = "cgu-logo",
  PAYMENTS = "payments",
  DASHBAORD_EXPENSES = "dashboard_expenses",
  INDIVIDUAL_DASHBOARD = "individual-dashboard",
  DATA = "data",
  DATA_PAYMENTS = "data/payments",
  DATA_TO_COMPLETE = "data-to-complete",
  BLACKLIST = "blacklist",
  TVA = "tva",
  GROUPS = "groups",
  PAYMENT_CONTROL = "payment-control",
  DRIVERS = "drivers",
  SWAN_REDIRECT = "swan/redirect",
}

export enum DataRoutes {
  PAYMENTS = "payments",
  INDIVIDUAL_DASHBOARD = "individual-dashboard",
  DASHBAORD_EXPENSES = "dashboard_expenses",
}

export enum RoutesUnAuth {
  LOGIN = "login",
  LOST_PASSWORD = "lost-password",
  REGISTER = "register",
  ACTIVATE_ACCOUNT = "activate-account/:token",
}

export interface Routes {
  label: string;
  path: DataRoutes;
  iconLight?: string;
  iconDark?: string;
}

export namespace DataRoutes {
  export const routes: any[] = [
    { label: "", path: DataRoutes.PAYMENTS, iconLight: CardLight, iconDark: CardDark },
    { label: "", path: DataRoutes.DASHBAORD_EXPENSES, iconLight: EuroWhiteIcon, iconDark: EuroBlackIcon },
    { label: "", path: DataRoutes.INDIVIDUAL_DASHBOARD, iconLight: LeafLight, iconDark: LeafDark },
  ];
}

export const useRouteMatch = (patterns: readonly string[] = []) => {
  const { pathname } = useLocation();

  for (let i = 0; i < patterns.length; i += 1) {
    const pattern = patterns[i];

    const possibleMatch = pathname.split("/").some((p) => {
      return p === pattern;
    });

    if (possibleMatch) {
      return pattern;
    }
  }

  return null;
};
