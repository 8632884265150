import { Stack, Typography } from "@mui/material";
import { useObservable } from "@ngneat/react-rxjs";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { scoreQuery } from "src/store/scores/score.query";
import { scoreService } from "src/store/scores/score.services";
import { useResponsive, useResponsiveTablet } from "src/utils/useResponsive";
import GroupScore from "./individualDashboardComponent/GroupScore.component";
import PersonalScore from "./individualDashboardComponent/PersonalScore.component";
import { DashboardExpensesFilters } from "@store/dashboardExpenses/dashboardExpenses.models";
import { CURRENT_YEAR_FIRST_JANUARY } from "../dashboardExpenses/DashboardExpenses.screen";
import DashboardExpensesFiltersModal from "../dashboardExpenses/DashboardExpensesFilters.modal";

const IndividualDashboradScreen = () => {
  const isMobile: boolean = useResponsive();
  const isTablet: boolean = useResponsiveTablet();
  const { t: t_dashboard } = useTranslation(undefined, {
    keyPrefix: "individualDashboard",
  });
  const [filters, setFilters] = useState<DashboardExpensesFilters>({
    from: CURRENT_YEAR_FIRST_JANUARY,
    to: new Date(),
  });

  const [{ scores }] = useObservable(scoreQuery.scores$);

  useEffect(() => {
    scoreService.getScore(filters).subscribe();
  }, [filters]);

  return (
    <Stack
      p={isMobile ? 0 : 3}
      width="100%"
      marginBottom={3}
      maxHeight={!isMobile ? "calc(100vh - 265px)" : undefined}
      overflow={!isMobile ? "auto" : undefined}
      spacing={3}
    >
      <Stack width={"100%"} flexWrap={"wrap"} direction={"row"} justifyContent={"space-between"}>
        <Stack>
          <Typography fontSize="30px" fontWeight="800">
            {t_dashboard("title")}
          </Typography>
          <Typography fontSize="23px" fontWeight="600" color="text.secondary">
            {t_dashboard("subtitle")}
          </Typography>
        </Stack>
        <DashboardExpensesFiltersModal filters={filters} setFilters={setFilters} />
      </Stack>
      <Stack
        display={"flex"}
        justifyContent={"center"}
        flexDirection={isMobile || isTablet ? "column" : "row"}
        gap={"30px"}
      >
        <PersonalScore scores={scores[0]} from_date={filters.from} to_date={filters.to} />
        <GroupScore scores={scores[0]} />
      </Stack>
    </Stack>
  );
};

export default IndividualDashboradScreen;
