import { catchError, from, map, Observable, tap } from "rxjs";
import { setEntities, updateEntities } from "@ngneat/elf-entities";
import { companiesStore } from "./companies.store";
import { Company, CompanyCard, CompanyFilter } from "./company.model";
import APIAxios, { APIRoutes } from "../../api/axios.api";
import SnackError from "../../utils/errors.utils";
import { AxiosError, AxiosResponse } from "axios";
import { getCompaniesDataSource } from "./companies.requests";
import { PaginationDto } from "@utils/pagination.dto";

export class CompaniesServices {
  store = companiesStore;

  resetStore = () => this.store.reset();

  setPagination = (pagination: PaginationDto) =>
    this.store.update((state) => ({
      ...state,
      pagination,
    }));

  setFilters = (filters: Partial<CompanyFilter>) =>
    this.store.update((state) => ({
      ...state,
      filters: {
        ...state.filters,
        filters,
      },
    }));

  getCompaniesLight = (filters: CompanyFilter): Observable<Company[]> => {
    return from(
      APIAxios({
        ...APIRoutes.getCompaniesLight(
          filters.page,
          filters.orderBy,
          filters.orderByDirection || "asc",
          filters.searchText
        ),
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<Company[]>) => {
        return response.data;
      }),
      tap((companies) => {
        this.store.update(
          setEntities(companies.filter((company) => company.name)),
          getCompaniesDataSource.setSuccess()
        );
      }),
      getCompaniesDataSource.trackRequestStatus()
    );
  };

  addRemoveCardFromGroup = (groupId: string, card: CompanyCard) => {
    return from(APIAxios({ ...APIRoutes.ToggleCardFromGroup(groupId, card.cardId) })).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<any>) => {
        return response.data;
      }),
      tap((responseCard) => {
        this.store.update(updateEntities(responseCard.company.id, responseCard.company));
      })
    );
  };

  removeGroup = (groupId: string): Observable<Company> => {
    return from(APIAxios({ ...APIRoutes.DELETEGroup(groupId) })).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<Company>) => {
        return response.data;
      }),
      tap((company) => {
        this.store.update(updateEntities(company.id, company));
      })
    );
  };
}

export const companiesServices = new CompaniesServices();
