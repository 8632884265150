import { select } from "@ngneat/elf";

import { companiesStore } from "./companies.store";
import { getCompaniesDataSource } from "./companies.requests";

export class CompaniesQuery {
  store = companiesStore;

  companies$ = getCompaniesDataSource.data$();
  pagination$ = this.store.pipe(select((state) => state.pagination));

  filters$ = this.store.pipe(select((state) => state.filters));
}

export const companiesQuery = new CompaniesQuery();
