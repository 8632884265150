import { useCallback, useEffect, useState, useRef, useMemo } from "react";
import { Stack, styled, Button, Typography, IconButton } from "@mui/material";
import { DataGrid, GridColDef, GridSortModel } from "@mui/x-data-grid";
import { ChevronLeft } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { searchCompanyUsersEffect, User, UserRole } from "../../../../store/users";
import { Colors } from "../../../../constants/colors.constants";
import { useObservable } from "@ngneat/react-rxjs";
import { usersQuery, usersServices } from "../../../../store/users";

import Header from "../../../../components/header/Header.component";
import BasicSearchbar from "../../../../components/inputs/BasicSearchBar.component";
import Delete from "../../../../assets/icons/icon_delete_color.svg";
import ConfirmDialog from "../../../../components/Confirm.component";
import { useEffectFn } from "@ngneat/effects-hooks";
import { sessionQuery } from "src/store/session";
import { enqueueSnackbar } from "notistack";
import { useTranslate } from "src/utils/i18n.utils";

const CustomDataGrid = styled(DataGrid)({
  border: "none !important",
  width: "100%",
  flexDirection: "column-reverse",
  "& .MuiDataGrid-row": {
    backgroundColor: Colors.white,
    borderRadius: "8px",
    marginTop: "4px",
    marginBottom: "4px",
    border: 0,
    maxHeight: "54px !important",
    minHeight: "54px !important",
    fontSize: "18px",
    fontWeight: "600",
  },
  "& .MuiDataGrid-columnHeaders": {
    border: 0,
  },
  "& .MuiDataGrid-columnHeaders:focus": {
    outline: "none",
  },
  "& .MuiDataGrid-cell": {
    border: 0,
    fontSize: "18px",
    fontWeight: 600,
    color: Colors.primaryText,
  },
  "& .MuiDataGrid-withBorderColor": {
    borderColor: "transparent",
  },
  "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus, .MuiDataGridCell--withRenderer":
    {
      outline: "none !important",
    },
  "& .MuiTablePagination-root": {
    position: "absolute",
    top: "52px",
    right: 0,
  },
});

const HeaderTypography = styled(Typography)({
  fontSize: "12px",
  color: Colors.secondaryText,
  fontWeight: "600",
  textTransform: "uppercase",
});

const CompanyUsers = () => {
  const { t } = useTranslate("drivers");
  const { t: tGlobal } = useTranslate("global");
  const navigate = useNavigate();

  const [filters] = useObservable(usersQuery.filters$);
  const [userToDelete, setUserToDelete] = useState<string | undefined>();
  const [deleteUserOpen, setDeleteUserOpen] = useState(false);

  const [{ company }] = useObservable(sessionQuery.company$);

  const searchCompanyUsers = useEffectFn(searchCompanyUsersEffect);

  const [{ users: drivers }] = useObservable(usersQuery.users$);
  const [pagination] = useObservable(usersQuery.pagination$);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const rowCountRef = useRef(pagination?.totalCount || 0);

  const rowCount = useMemo(() => {
    if (pagination?.totalCount !== undefined) {
      rowCountRef.current = pagination.totalCount;
    }
    return rowCountRef.current;
  }, [pagination?.totalCount]);

  useEffect(() => {
    usersServices.setFilters({
      page: paginationModel.page + 1,
      companyId: company?.id,
      hasNoAccountHolderId: true,
      pageSize: 10,
    });
  }, [paginationModel, company]);

  const handleSortModelChange = useCallback(
    (sortModel: GridSortModel) => {
      usersServices.setFilters({
        page: 1,
        orderByDirection: sortModel[0]?.sort || undefined,
        orderBy: sortModel[0]?.field as keyof User | undefined,
        hasNoAccountHolderId: true,
        companyId: company?.id,
      });
    },
    [company]
  );

  const handleDeleteUser = () => {
    if (!userToDelete) return;
    usersServices.deleteUser(userToDelete).subscribe({
      next: () => {
        enqueueSnackbar(t("users.delete.success"), { variant: "success" });
        setDeleteUserOpen(false);
      },
      error: (err) => enqueueSnackbar(err.text, err.options),
    });
    setUserToDelete(undefined);
  };

  useEffect(() => {
    console.log(filters);
    if (filters.companyId) {
      searchCompanyUsers(filters);
    }
  }, [filters, searchCompanyUsers]);

  useEffect(() => {
    userToDelete && setDeleteUserOpen(true);
  }, [userToDelete]);

  const columns: GridColDef[] = [
    {
      field: "firstName",
      renderHeader: () => <HeaderTypography>{t("firstName")}</HeaderTypography>,
      minWidth: 230,
      flex: 1,
      renderCell(params) {
        return (
          <Typography fontSize="18px" fontWeight="600">
            {params.row.firstName}
          </Typography>
        );
      },
    },
    {
      field: "lastName",
      renderHeader: () => <HeaderTypography>{t("lastName")}</HeaderTypography>,
      minWidth: 150,
      flex: 2,
      renderCell(params) {
        return (
          <Typography fontSize="18px" fontWeight="600">
            {params.row.lastName}
          </Typography>
        );
      },
    },
    {
      field: "email",
      renderHeader: () => <HeaderTypography>{t("email")}</HeaderTypography>,
      minWidth: 150,
      flex: 2,
      renderCell(params) {
        return (
          <Typography fontSize="18px" fontWeight="600">
            {params.row.email}
          </Typography>
        );
      },
    },
    {
      field: "phone",
      renderHeader: () => <HeaderTypography>{t("phone")}</HeaderTypography>,
      minWidth: 150,
      flex: 2,
      renderCell(params) {
        return (
          <Typography fontSize="18px" fontWeight="600">
            {params.row.phoneNumber}
          </Typography>
        );
      },
    },
    {
      field: "delete",
      headerName: "",
      width: 45,
      renderCell(params) {
        return (
          <IconButton
            onClick={() => {
              console.log(params.row.id);
              setUserToDelete(params.row.id);
            }}
          >
            <img src={Delete} alt="" />
          </IconButton>
        );
      },
    },
  ];

  return (
    <>
      {deleteUserOpen && (
        <ConfirmDialog
          title={t("delete.title")}
          confirm={tGlobal("validate")}
          cancel={tGlobal("cancel")}
          handleClose={() => {
            setUserToDelete(undefined);
            setDeleteUserOpen(false);
          }}
          handleValidate={() => handleDeleteUser()}
        />
      )}
      <Stack pt={3} gap={3} height="100%">
        <Header role={UserRole.ADMIN_COMPANY} selectedTab="params" />
        <Stack alignItems="start" gap={2}>
          <Button variant="text" startIcon={<ChevronLeft />} onClick={() => navigate(-1)} aria-label="back">
            {tGlobal("back")}
          </Button>
          <Stack direction="row" justifyContent="space-between" width="100%">
            <Typography fontSize="30px" fontWeight={800} color={Colors.primaryText}>
              {t("title")}
            </Typography>
            <Stack direction="row" gap={2}>
              <BasicSearchbar
                placeholder={tGlobal("search") || ""}
                onChange={(value) => {
                  usersServices.setFilters({ searchText: value });
                }}
              />
            </Stack>
          </Stack>
        </Stack>
        <CustomDataGrid
          rows={drivers}
          pagination
          rowCount={rowCount}
          paginationMode="server"
          paginationModel={paginationModel}
          onPaginationModelChange={(model) => {
            setPaginationModel(model);
          }}
          disableColumnMenu
          disableColumnSelector
          disableRowSelectionOnClick
          disableDensitySelector
          disableColumnFilter
          rowSelection={false}
          filterMode="server"
          disableVirtualization
          sortingMode="server"
          onSortModelChange={handleSortModelChange}
          columns={columns}
          pageSizeOptions={[]}
          slotProps={{
            pagination: {
              labelRowsPerPage: "test",
            },
          }}
        />
      </Stack>
    </>
  );
};

export default CompanyUsers;
