import { catchError, from, map, Observable, tap } from "rxjs";
import { addEntities, deleteEntities, setEntities, updateEntities } from "@ngneat/elf-entities";
import { categoryChildrenStore } from "./categoryChildren.store";
import { CategoryChildrenFilter, CategoryChildren, GetCategoryChildren } from "./categoryChildren.models";
import { getCategoryChildrenDataSource } from "./categoryChildren.requests";
import APIAxios, { APIRoutes } from "../../api/axios.api";
import { AxiosError, AxiosResponse } from "axios";
import SnackError from "../../utils/errors.utils";
import { PaginationDto } from "@utils/pagination.dto";

export class CategoryChildrenStore {
  store = categoryChildrenStore;

  resetStore = () => this.store.reset();
  setFilters = (filters: Partial<CategoryChildrenFilter>) =>
    this.store.update((state) => ({
      ...state,
      filters: {
        ...state.filters,
        ...filters,
      },
    }));

  setPagination = (pagination: PaginationDto) =>
    this.store.update((state) => ({
      ...state,
      pagination,
    }));

  addCategoryChild = (newCategory: Partial<CategoryChildren>): Observable<CategoryChildren> => {
    return from(
      APIAxios({
        ...APIRoutes.POSTCategoryChildren(),
        data: {
          ...newCategory,
          serviceCategory: newCategory.serviceCategory?.id,
        },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<CategoryChildren>) => {
        return response.data;
      }),
      tap((category) => {
        this.store.update(addEntities(category));
      })
    );
  };

  getCategoryChildren = (filters: CategoryChildrenFilter): Observable<GetCategoryChildren> => {
    return from(
      APIAxios({
        ...APIRoutes.GETCategoryChildren(filters.page, filters.orderBy, filters.orderByDirection || "asc"),
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<GetCategoryChildren>) => {
        return response.data;
      }),
      tap((categories) => {
        this.store.update(
          setEntities(categories.serviceCategoriesChildren),
          getCategoryChildrenDataSource.setSuccess()
        );
        this.setPagination(categories);
      }),
      getCategoryChildrenDataSource.trackRequestStatus()
    );
  };

  updateCategoryChild = (category: Partial<CategoryChildren>): Observable<CategoryChildren> => {
    return from(
      APIAxios({
        ...APIRoutes.PUTCategoryChildren(category?.id ?? ""),
        data: {
          ...category,
          serviceCategory: category.serviceCategory?.id,
          id: undefined,
        },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<CategoryChildren>) => {
        return response.data;
      }),
      tap((category) => {
        this.store.update(updateEntities(category.id, category));
      })
    );
  };

  deleteCategoryChild = (categoryId: string): Observable<AxiosResponse> => {
    return from(APIAxios(APIRoutes.DELETECategoryChildren(categoryId))).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<AxiosResponse>) => {
        this.store.update(deleteEntities(categoryId));
        return response.data;
      })
    );
  };
}

export const categoryChildrenServices = new CategoryChildrenStore();
