import { createStore, withProps } from "@ngneat/elf";
import { withEntities } from "@ngneat/elf-entities";
import { withRequestsStatus } from "@ngneat/elf-requests";

import { PaginationDto } from "@utils/pagination.dto";
import { Company } from "./company.model"; // Ensure both are imported
import { CompanyFilter } from "./company.model";

interface CompanyStoreProps {
  filters: CompanyFilter;
  pagination?: PaginationDto;
}

export const companiesStore = createStore(
  { name: "companies" },
  withProps<CompanyStoreProps>({
    filters: { page: 1, orderBy: "name" },
  }),
  withEntities<Company>(),
  withRequestsStatus()
);
