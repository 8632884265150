import { array, mixed, number, object, string, ValidationError } from "yup";
import * as Yup from "yup";
import I18nUtils from "src/utils/i18n.utils";

const { t } = I18nUtils;

Yup.setLocale({
  mixed: {
    required: t("errors.required") || "",
  },
  string: {
    email: t("errors:field.fieldEmail") || "",
  },
});

export interface FieldErrors {
  [field: string]: string;
}

export enum FieldValidationType {
  REQUIRED_STRING = "REQUIRED_STRING",
  REQUIRED_NUMBER = "REQUIRED_NUMBER",
  REQUIRED_EMAIL = "REQUIRED_EMAIL",
  REQUIRED_ARRAY = "REQUIRED_ARRAY",
  REQUIRED_SELECT_ITEM = "REQUIRED_SELECT_ITEM",
  REQUIRED_PASSWORD = "REQUIRED_PASSWORD",
  REQUIRED_FILE = "REQUIRED_FILE",
  REQUIRED_PHONE = "REQUIRED_PHONE",
}

const phoneRegex = /^\+?\d{10,15}$/;

export const fieldsValidation = {
  [FieldValidationType.REQUIRED_STRING]: string()
    .nullable()
    .required(t("errors.required") || ""),
  [FieldValidationType.REQUIRED_EMAIL]: string()
    .nullable()
    .required(t("errors.required") || "")
    .email(t("errors.email") || ""),
  [FieldValidationType.REQUIRED_ARRAY]: array()
    .nullable()
    .required(t("errors.required") || "")
    .min(1, t("errors.required") || ""),
  [FieldValidationType.REQUIRED_NUMBER]: number()
    .nullable()
    .required(t("errors.required") || ""),
  [FieldValidationType.REQUIRED_PHONE]: string()
    .nullable()
    .required(t("errors.required") || "")
    .matches(phoneRegex, t("errors.invalidPhone") || ""),
  [FieldValidationType.REQUIRED_SELECT_ITEM]: object()
    .nullable()
    .required(t("errors.required") || ""),
  [FieldValidationType.REQUIRED_FILE]: mixed().required(t("errors.importFile") || ""),
  [FieldValidationType.REQUIRED_PASSWORD]: string()
    .required(t("errors.required") || "")
    .min(8, t("errors.tooShort", { count: 8 }) || ""),
};

export const getFieldError = (value: any, validationType: FieldValidationType) => {
  try {
    fieldsValidation[validationType].validateSync(value);
  } catch (err: any) {
    return err.message;
  }
};

export const checkIfErrors: any = (errors: Object) =>
  Object.values(errors).some((err) =>
    typeof err === "string" || typeof err === "undefined" ? !!err : checkIfErrors(err)
  );

export const transformError = (err: ValidationError): FieldErrors => {
  return err.inner.reduce((formError: any, innerError: any) => {
    const oldError = formError[innerError.path!];

    return {
      ...formError,
      [innerError.path!]: oldError || innerError.message,
    };
  }, {} as FieldErrors);
};
