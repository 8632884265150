import React, { useEffect, useState } from "react";
import { AppBar, Button, CardActionArea, Menu, MenuItem, Stack, Typography, styled } from "@mui/material";
import { Sizes } from "../../constants/sizes.constants";
import { Colors } from "../../constants/colors.constants";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { Pages } from "../../utils/routes.utils";

import { sessionQuery } from "../../store/session/session.query";
import { sessionService } from "../../store/session";
import { UserRole } from "../../store/users";

import SuperAdminDashboard from "./superAdmin/dashboard/SuperAdminDashboard.screen";
import Logo from "../../assets/images/img_logo.svg";
import CategoryScreen from "./categories/Categories.screen";
import AddCategory from "./categories/AddCategory.modale";
import MIDExceptionsScreen from "./midExceptions/MIDExceptions.screen";
import ChevronDown from "../../assets/icons/icon_arrow_down_dark.svg";
import { LogoutRounded } from "@mui/icons-material";
import LogoCGU from "./common/cgu/LogoCGU.screen";
import AdminCompanyDashboard from "./adminCompany/dashboard/AdminCompanyDashboard.screen";
import PaymentScreen from "./adminCompany/payments/Payments.screen";
import Data from "./adminCompany/data/Data.screen";
import InformationsToComplete from "./adminCompany/informationsToComplete/InformationsToComplete.screen";
import Blacklist from "./adminCompany/blacklist/Blacklist.screen";
import TVA from "./adminCompany/tva/TVA.screen";
import Groups from "./adminCompany/groups/Groups.screen";
import PaymentControl from "./adminCompany/paymentControl/PaymentControl.screen";
import ChildrenCategoryScreen from "./adminCompany/categoryChildren/CategoryChildren.screen";
import { globalSettingsQuery, globalSettingsServices } from "../../store/globalSettings";
import { enqueueSnackbar } from "notistack";
import { useObservable } from "@ngneat/react-rxjs";
import AccountHolderScreen from "./superAdmin/accountHolders/AccountHolders.screen";
import { resetAccountToSuperAdmin } from "src/store/accountHolders/accountHolders.services";
import { useTranslation } from "react-i18next";
import DashboardExpensesScreen from "./adminCompany/dashboardExpenses/DashboardExpenses.screen";
import IndividualDashboradScreen from "./adminCompany/payments/IndividualDashborad.screen";
import { useResponsive } from "src/utils/useResponsive";
import DriversScreen from "./adminCompany/drivers/Drivers.screen";

const Container = styled(Stack)`
  position: relative;
  z-index: 1;
  height: 100%;
  flex: 1;
`;

const Auth = React.memo(() => {
  const user = sessionQuery.user;
  const { t } = useTranslation();

  const [{ globalSettings }] = useObservable(globalSettingsQuery.globalSettings$);
  const isMobile = useResponsive();

  const [logoUrl, setLogoUrl] = useState<string | undefined>();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const isSuperAdminWithoutAccountHolder =
    sessionQuery.role === UserRole.SUPER_ADMIN && !sessionQuery.user.accountHolderId;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (globalSettings) return;
    globalSettingsServices.getGlobalSettings().subscribe({
      error: (err) => enqueueSnackbar(err.text, err.options),
      next: (value) => {
        globalSettingsServices.getFile(value.logoUrl).subscribe({
          next: (value) => setLogoUrl(value.url),
        });
      },
    });
    if (user.role === UserRole.SUPER_ADMIN) return;
    sessionService.getCompany().subscribe({
      error: (err) => enqueueSnackbar(err.text, err.options),
      next: (value) => {
        globalSettingsServices.getFile(value.logoUrl).subscribe({
          next: (value) => setLogoUrl(value.url),
        });
      },
    });
  });

  return (
    <>
      <AppBar>
        <Stack
          direction="row"
          height={Sizes.topBar}
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          position="absolute"
          zIndex={10}
          bgcolor={Colors.white}
          p="22px"
          pl="40px"
          pr="40px"
        >
          <img alt="company-logo" src={Logo} style={{ height: "30px" }} />
          {!isMobile && sessionQuery.role === UserRole.SUPER_ADMIN && user.accountHolderId && (
            <Button
              variant="contained"
              onClick={() =>
                resetAccountToSuperAdmin().then((x) => {
                  navigate(`/${Pages.DASHBOARD}`);
                  window.location.reload();
                })
              }
            >
              {t("backToSuperAdmin")}
            </Button>
          )}
          <Stack direction="row" gap={2}>
            <img
              style={{
                height: "40px",
                width: "40px",
                borderRadius: "20px",
              }}
              alt=""
              src={logoUrl}
            />

            <Stack direction="column" style={{ position: "relative" }}>
              <CardActionArea onClick={handleClick}>
                <Stack direction="row" gap={1} alignItems="baseline">
                  <Typography color={Colors.primaryText} fontSize={16} fontWeight={700}>
                    {user.firstName + " " + user.lastName}
                  </Typography>
                  <img src={ChevronDown} alt="" />
                </Stack>
              </CardActionArea>
              <Typography color={Colors.secondaryText} fontSize={12} fontWeight={400}>
                {UserRole.label(user.role)}
              </Typography>
              <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                {isMobile && (
                  <MenuItem
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    <Typography
                      onClick={() =>
                        resetAccountToSuperAdmin().then((x) => {
                          navigate(`/${Pages.DASHBOARD}`);
                          window.location.reload();
                        })
                      }
                    >
                      {t("backToSuperAdmin")}
                    </Typography>
                  </MenuItem>
                )}
                <MenuItem
                  onClick={() => {
                    handleClose();
                    return sessionService.logout();
                  }}
                >
                  <Stack direction="row" gap={1}>
                    <LogoutRounded color="error" />
                    <Typography color="error" fontSize={16} fontWeight={400}>
                      Déconnexion
                    </Typography>
                  </Stack>
                </MenuItem>
              </Menu>
            </Stack>
          </Stack>
        </Stack>
      </AppBar>
      <Container paddingTop={Sizes.topBar} ml={4} mr={4}>
        <Routes>
          {sessionQuery.role === UserRole.SUPER_ADMIN && !sessionQuery.user.accountHolderId && (
            <>
              <Route path={Pages.DASHBOARD} element={<SuperAdminDashboard />} />
              <Route path={Pages.CATEGORIES} element={<CategoryScreen />} />
              <Route path={Pages.CATEGORIES} element={<CategoryScreen />}>
                <Route path={":categoryId"} element={<AddCategory />} />
                <Route path={Pages.ADD_CATEGORY} element={<AddCategory />} />
              </Route>
              <Route path={Pages.MID} element={<MIDExceptionsScreen />} />
              <Route path={Pages.ACCOUNT_HOLDER} element={<AccountHolderScreen />} />
              <Route path={Pages.CGU_LOGO} element={<LogoCGU />} />
              <Route path="*" element={<Navigate to={Pages.DASHBOARD} replace />} />
            </>
          )}
          {(sessionQuery.role === UserRole.ADMIN_COMPANY ||
            (sessionQuery.role === UserRole.SUPER_ADMIN && sessionQuery.user.accountHolderId)) && (
            <>
              <Route path={Pages.DASHBOARD} element={<AdminCompanyDashboard />} />
              <Route path={Pages.DRIVERS} element={<DriversScreen />} />
              <Route path={Pages.CHILDREN_CATEGORY} element={<ChildrenCategoryScreen />} />
              <Route path={Pages.DATA_TO_COMPLETE} element={<InformationsToComplete />} />
              <Route path={Pages.BLACKLIST} element={<Blacklist />} />
              <Route path={Pages.TVA} element={<TVA />} />
              <Route path={Pages.GROUPS} element={<Groups />} />
              <Route path={Pages.PAYMENT_CONTROL} element={<PaymentControl />} />
              <Route path={Pages.DATA} element={<Data role={sessionQuery.role} />}>
                <Route path={Pages.PAYMENTS} element={<PaymentScreen role={sessionQuery.role} />} />
                <Route path={Pages.INDIVIDUAL_DASHBOARD} element={<IndividualDashboradScreen />} />
                <Route path={Pages.DASHBAORD_EXPENSES} element={<DashboardExpensesScreen />} />
              </Route>
              <Route path={Pages.CGU_LOGO} element={<LogoCGU />} />
              <Route path="*" element={<Navigate to={Pages.DATA_PAYMENTS} replace />} />
            </>
          )}
          {sessionQuery.role === UserRole.EMPLOYEE && (
            <>
              <Route path={Pages.CHILDREN_CATEGORY} element={<ChildrenCategoryScreen />} />
              <Route path={Pages.DATA_TO_COMPLETE} element={<InformationsToComplete />} />

              <Route path={Pages.BLACKLIST} element={<Blacklist />} />
              <Route path={Pages.TVA} element={<TVA />} />
              <Route path={Pages.GROUPS} element={<Groups />} />
              <Route path={Pages.PAYMENT_CONTROL} element={<PaymentControl />} />
              <Route path={Pages.DATA} element={<Data role={sessionQuery.role} />}>
                <Route path={Pages.PAYMENTS} element={<PaymentScreen role={sessionQuery.role} />} />
                <Route path={Pages.INDIVIDUAL_DASHBOARD} element={<IndividualDashboradScreen />} />
                <Route path={Pages.DASHBAORD_EXPENSES} element={<DashboardExpensesScreen />} />
              </Route>
              <Route path={Pages.CGU_LOGO} element={<LogoCGU />} />
              <Route path="*" element={<Navigate to={`${Pages.DATA}/${Pages.PAYMENTS}`} replace />} />
            </>
          )}
        </Routes>
      </Container>
    </>
  );
});

export default Auth;
