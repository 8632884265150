import dayjs from "dayjs";
import { initReactI18next, useTranslation } from "react-i18next";
import i18n from "i18next";

import { Locales, LocaleTranslations } from "src/constants/locales.constants";

export const useTranslate = (namespace: string) => useTranslation(undefined, { keyPrefix: namespace });

const formatTranslation = (value: any, format?: string, locale?: string) => {
  if (value instanceof Date && locale && format) {
    return dayjs(value).locale(locale).format(format);
  }

  if (typeof value === "string" && format === "uppercase") {
    return value.toUpperCase();
  }

  if (typeof value === "string" && format === "capitalize") return value.charAt(0).toUpperCase() + value.slice(1);

  if (typeof value === "string" && format === "lowercase") {
    return value.toLowerCase();
  }

  return value;
};

i18n.use(initReactI18next).init({
  resources: LocaleTranslations,
  fallbackLng: Locales.FR,
  returnObjects: true,
  returnNull: false,
  interpolation: {
    escapeValue: false,
    format: formatTranslation,
  },
});

export default i18n;
