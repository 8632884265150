import { FormEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { Colors } from "src/constants/colors.constants";
import { Stack, Typography, Button, IconButton } from "@mui/material";

import BasicTextfield from "src/components/inputs/BasicTextfield.component";

import Logo from "src/assets/images/img_logo.svg";
import Arobase from "src/assets/icons/icon_at_grey.svg";
import Lock from "src/assets/icons/icon_lock_grey.svg";
import { FieldErrors } from "src/utils/yup.utils";
import { sessionService } from "src/store/session/session.service";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { UserRole } from "src/store/users";
import { object, string } from "yup";
import * as YupUtils from "src/utils/yup.utils";
import I18n from "src/utils/i18n.utils";
import { AuthData } from "src/store/session";
import { RoutesUnAuth } from "src/utils/routes.utils";

export const signInValidation = (signInData: AuthData) => {
  const requiredString = string().required(I18n.t("errors.required") || "");
  try {
    const schema = object().shape({
      email: requiredString.email(I18n.t("errors.email") || ""),
      password: requiredString,
    });

    schema.validateSync(signInData, { abortEarly: false });
    return {};
  } catch (err) {
    return YupUtils.transformError(err as any);
  }
};

const LoginForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [passwordHidden, setPasswordHidden] = useState(true);

  const [data, setData] = useState<AuthData>({});
  const [errors, setErrors] = useState<FieldErrors>({});

  const updateData = (name: string) => (value: any) => setData({ ...data, [name]: value });

  const handleAuthent = (evt: FormEvent) => {
    evt.preventDefault();
    const errors = signInValidation(data);

    setErrors(errors);
    if (!!Object.keys(errors).length) return;

    sessionService
      .login({
        ...data,
        email: data.email?.toLowerCase(),
      })
      .subscribe({
        error: (err) => {
          enqueueSnackbar(err.text, err.options);
        },
        next: (value) => {
          if (value.user.role !== UserRole.SUPER_ADMIN) {
            sessionService.getCompany().subscribe({
              error: (err) => enqueueSnackbar(err.text, err.options),
            });
          }
        },
      });
  };

  return (
    <Stack
      alignItems="center"
      gap={6}
      justifyContent="space-evenly"
      height="100%"
      width={{ xs: "100%", md: "70%" }}
      paddingTop={6}
    >
      <img alt="logo" src={Logo} />
      <Stack alignItems="center">
        <Typography fontSize="30px" fontWeight="800">
          {t("login.form.title")}
        </Typography>
        <Typography fontSize="18px" fontWeight="400" color={Colors.secondaryText}>
          {t("login.form.subtitle")}
        </Typography>
      </Stack>
      <form onSubmit={handleAuthent} noValidate>
        <Stack gap={3} width="100%">
          <BasicTextfield
            value={data.email || ""}
            onChange={updateData("email")}
            error={errors.email}
            placeholder={t("login.email") ?? ""}
            endAdornment={<img src={Arobase} alt="" />}
          />
          <Stack gap={2}>
            <BasicTextfield
              value={data.password || ""}
              onChange={updateData("password")}
              password={passwordHidden}
              error={errors.password}
              placeholder={t("login.password") ?? ""}
              endAdornment={
                <>
                  <IconButton onClick={() => setPasswordHidden(!passwordHidden)}>
                    {passwordHidden ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                  <img src={Lock} alt="" />
                </>
              }
            />
            <Stack>
              <Typography align="center" fontSize="14px" fontWeight="400" color={Colors.secondaryText}>
                {t("login.form.passwordRequirements")}
              </Typography>
              <Button
                aria-label="underline"
                onClick={() => navigate("/lost-password")}
                style={{ color: Colors.primaryText }}
              >
                {t("login.form.forgotPassword")}
              </Button>
            </Stack>
          </Stack>
          <Button type="submit" fullWidth color="secondary" variant="contained">
            {t("login.form.loginAction")}
          </Button>
        </Stack>
      </form>
      <Stack alignItems="center">
        <Typography fontSize="16px" fontWeight="500">
          {t("login.noAccountYet")}
        </Typography>
        <Button onClick={() => (window.location.href = RoutesUnAuth.REGISTER)} aria-label="underline" color="primary">
          {t("login.register")}
        </Button>
      </Stack>
    </Stack>
  );
};

export default LoginForm;
