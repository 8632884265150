import { Grid, Stack, Typography } from "@mui/material";
import { Colors } from "../../../../constants/colors.constants";
import { useNavigate } from "react-router-dom";

import Dashboard from "../../../../assets/icons/icon_category_color.svg";
import Contract from "../../../../assets/icons/icon_contract_color.svg";
import Exclamation from "../../../../assets/icons/icon_exclamationmark_exception_color.svg";
import DriverIcon from "../../../../assets/icons/driver.svg";
import TVA from "../../../../assets/icons/icon_percent_tva_color.svg";
import Blacklist from "../../../../assets/icons/icon_prohibition_color.svg";
import Group from "../../../../assets/icons/icon_group_color.svg";
import Settings from "../../../../assets/icons/icon_wrench_color.svg";

import DashboardCard from "../../../../components/card/DashboardCard.component";
import Header from "../../../../components/header/Header.component";
import { UserRole } from "../../../../store/users";
import { useEffect, useState } from "react";
import SuspendSepaModal from "./SuspendSepa.modal";
import { sessionQuery, sessionService } from "src/store/session";
import { useObservable } from "@ngneat/react-rxjs";
import CreateCardMassModal from "./createCardMass/CreateCardMass.modal";
import APIAxios from "src/api/axios.api";
import { enqueueSnackbar } from "notistack";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import { useTranslate } from "src/utils/i18n.utils";
import { useResponsive } from "src/utils/useResponsive";
import SettingsSharedCardModal from "./SettingsSharedCard.modal";

const AdminCompanyDashboard = () => {
  const navigate = useNavigate();
  const [openSepaModal, setOpenSepaModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openCreateCardMassModal, setOpenCreateCardMassModal] = useState(false);
  const [settingsSharedCardModal, setSettingsSharedCardModal] = useState(false);
  const [{ company }] = useObservable(sessionQuery.company$);
  const { t: t_global } = useTranslate("global");
  const isMobile: boolean = useResponsive();

  useEffect(() => {
    sessionService.getCompany().subscribe();
  }, []);

  const handleCreateCardMass = async () => {
    setLoading(true);
    try {
      const res = await APIAxios.get(`/swan/token-check`);
      if (res?.data?.redirectUrl) {
        window.open(res.data.redirectUrl, "_blank");
        enqueueSnackbar("Veuillez vous connecter à votre compte Swan pour créer des cartes en masse", {
          variant: "error",
        });
      } else {
        setOpenCreateCardMassModal(true);
      }
    } catch (error) {
      enqueueSnackbar("An error occurred while checking the token.", {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Stack gap={4} mt={3} style={{ backgroundColor: Colors.background }} pb={3}>
      {/* <Stack justifyContent='flex-end' direction='row' width='100%' gap={2}>
        <IconButton aria-label='dashboard' color='secondary'><img alt='' src={File} /></IconButton>
        <IconButton aria-label='dashboard' color='primary'><img alt='' src={Help} /></IconButton>
      </Stack> */}
      <Header role={UserRole.ADMIN_COMPANY} selectedTab={"params"} />
      <Typography fontSize={30} fontWeight={800} color={Colors.primaryText}>
        Tous vos paramètres
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <DashboardCard
            icon={<img alt="" src={Dashboard} />}
            title="Catégories d'achat et comptes comptables"
            subtitle="Vous pouvez relier les catégories d'achat à vos comptes comptables (6x), pour l’export Excel des paiements à télécharger dans votre outil comptable"
            onClick={() => navigate("/categories-company-settings")}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <DashboardCard
            icon={<img alt="" src={Settings} />}
            title="Données à compléter"
            subtitle="L'utilisateur doit fournir un justificatif ou renseigner un motif pour chaque paiement ? Vous pouvez définir vos exigences"
            onClick={() => navigate("/data-to-complete")}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <DashboardCard
            icon={<img alt="" src={TVA} />}
            title="TVA"
            subtitle="Vous pouvez définir vos règles de calcul automatique de la TVA récupérable par catégorie d'achat"
            onClick={() => navigate("/tva")}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <DashboardCard
            icon={<img alt="" src={Blacklist} />}
            title="Liste noire"
            subtitle="Vous pouvez mettre certains commerçants sur liste noire"
            onClick={() => navigate("/blacklist")}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <DashboardCard
            icon={<img alt="" src={Group} />}
            title="Groupes de cartes"
            subtitle="Vous pouvez définir vos groupes de cartes pour suivre les paiements ou définir vos règles de contrôle automatique des paiements selon ces groupes"
            onClick={() => navigate("/groups")}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <DashboardCard
            icon={<img alt="" src={Contract} />}
            title="Contrôle automatique des paiements"
            subtitle="Vous pouvez définir vos règles de contrôle automatique d’utilisation de la carte par groupe d’utilisateurs"
            onClick={() => navigate("/payment-control")}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <DashboardCard
            icon={<img alt="" src={Exclamation} />}
            title="Logo et politique de frais"
            subtitle="Vous pouvez télécharger votre logo et vos règles d’utilisation de la carte qui seront affichés dans l’en-tête de l’application"
            onClick={() => navigate("/cgu-logo")}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <DashboardCard
            icon={<img alt="" src={Exclamation} />}
            title="Suspendre un mandat SEPA"
            subtitle="Vous pouvez suspendre un mandat SEPA grâce à son ID"
            onClick={() => setOpenSepaModal(true)}
          />
        </Grid>
        {company?.hasDrivers && (
          <Grid item xs={12} md={6}>
            <DashboardCard
              icon={<img alt="" src={DriverIcon} />}
              title="Chauffeurs"
              subtitle="Vous pouvez gérer les chauffeurs de vos véhicules partagés"
              onClick={() => navigate("/drivers")}
            />
          </Grid>
        )}
        <Grid position="relative" item xs={12} md={6}>
          {loading && (
            <Grid
              bgcolor="rgba(0, 0, 0, 0.5)"
              width={"100%"}
              height={isMobile ? "230" : "200px"}
              borderRadius="14px"
              position="absolute"
              display="flex"
              p={isMobile ? "20px" : "40px"}
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              gap={2}
              zIndex={200}
            >
              <Typography fontSize="18px" fontWeight={600} color="white">
                {t_global("loading")}
              </Typography>
              <HourglassEmptyIcon style={{ color: "white", animation: "rotationHourglass 2s infinite linear" }} />
            </Grid>
          )}
          <DashboardCard
            icon={<img alt="" src={Contract} />}
            title="Création de cartes"
            subtitle="Vous pouvez créer des cartes"
            onClick={handleCreateCardMass}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <DashboardCard
              icon={<img alt="" src={Contract} />}
              title="Gestion des cartes partagées"
              subtitle="Vous pouvez gerer les paramètres des cartes partagées"
              onClick={() => setSettingsSharedCardModal(true)}
          />
        </Grid>
      </Grid>
      <SuspendSepaModal open={openSepaModal} handleClose={() => setOpenSepaModal(false)} />
      <CreateCardMassModal open={openCreateCardMassModal} handleClose={() => setOpenCreateCardMassModal(false)} />
      <SettingsSharedCardModal open={settingsSharedCardModal} handleClose={() => setSettingsSharedCardModal(false)} />
    </Stack>
  );
};

export default AdminCompanyDashboard;
