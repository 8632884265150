import { catchError, from, map, Observable, tap } from "rxjs";
import { addEntities, deleteEntities, setEntities, updateEntities } from "@ngneat/elf-entities";
import { midStore } from "./mid.store";
import { MIDFilters, MIDException, GetMIDExceptions } from "./mid.models";
import { getMidDataSource } from "./mid.requests";
import APIAxios, { APIRoutes } from "../../api/axios.api";
import SnackError from "../../utils/errors.utils";
import { AxiosError, AxiosResponse } from "axios";
import { PaginationDto } from "@utils/pagination.dto";

export class MIDServices {
  store = midStore;

  resetStore = () => this.store.reset();
  setFilters = (filters: Partial<MIDFilters>) =>
    this.store.update((state) => ({
      ...state,
      filters: {
        ...state.filters,
        ...filters,
      },
    }));

  setPagination = (pagination: PaginationDto) =>
    this.store.update((state) => ({
      ...state,
      pagination,
    }));

  getMidExceptions = (filters: MIDFilters): Observable<GetMIDExceptions> => {
    return from(
      APIAxios({
        ...APIRoutes.GETMIDExceptions(
          filters.page,
          filters.orderBy,
          filters.orderByDirection || "asc",
          filters.searchText
        ),
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<GetMIDExceptions>) => {
        return response.data;
      }),
      tap((midExceptions) => {
        this.store.update(setEntities(midExceptions.midExceptions), getMidDataSource.setSuccess());
        this.setPagination(midExceptions);
      }),
      getMidDataSource.trackRequestStatus()
    );
  };

  addMidException = (newMid: Partial<MIDException>): Observable<MIDException> => {
    return from(
      APIAxios({
        ...APIRoutes.POSTMIDException(),
        data: {
          ...newMid,
          serviceCategory: newMid.serviceCategory?.id,
        },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<MIDException>) => {
        return response.data;
      }),
      tap((mid) => {
        this.store.update(addEntities(mid));
      })
    );
  };
  requestMidException = (newMid: Partial<MIDException>): Observable<MIDException> => {
    return from(
      APIAxios({
        ...APIRoutes.POSTMIDExceptionRequest(),
        data: {
          ...newMid,
          serviceCategory: newMid.serviceCategory?.id,
        },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<MIDException>) => {
        return response.data;
      }),
      tap((mid) => {
        this.store.update(addEntities(mid));
      })
    );
  };

  updateMidException = (midException: Partial<MIDException>): Observable<MIDException> => {
    return from(
      APIAxios({
        ...APIRoutes.PUTMIDException(midException.id ?? ""),
        data: {
          ...midException,
          serviceCategory: midException.serviceCategory?.id,
          id: undefined,
        },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<MIDException>) => {
        return response.data;
      }),
      tap((mid) => {
        this.store.update(updateEntities(mid.id, mid));
      })
    );
  };

  deleteMidException = (midId: string): Observable<AxiosResponse> => {
    return from(APIAxios(APIRoutes.DELETEMIDException(midId))).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<AxiosResponse>) => {
        midExceptionsServices.store.update(deleteEntities(midId));
        return response.data;
      })
    );
  };
}

export const midExceptionsServices = new MIDServices();
