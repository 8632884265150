import APIAxios, { APIRoutes } from "src/api/axios.api";
import { AccountHolders } from "./accountHolders.models";
import { enqueueSnackbar } from "notistack";

export const getAccountHolders = async () => {
  try {
    const res = await APIAxios<AccountHolders[]>({ ...APIRoutes.GetAccountHolders() });
    if (res.data) {
      return res.data;
    }
  } catch (err) {
    enqueueSnackbar("Une erreur est survenue. Veuillez réessayer plus tard.", { variant: "error" });
  }
};

export const blockAccount = async (accountHolderId: string) => {
  try {
    const res = await APIAxios({ ...APIRoutes.PatchBlockAccount(accountHolderId) });
    if (res.data) {
      return res.data;
    }
  } catch (err) {
    enqueueSnackbar("Une erreur est survenue. Veuillez réessayer plus tard.", { variant: "error" });
  }
};

export const setSuperAdminToHolder = async (accountHolderId: string) => {
  try {
    const res = await APIAxios<AccountHolders[]>({ ...APIRoutes.setSuperAdminToHolder(accountHolderId) });
    if (res.data) {
      enqueueSnackbar("Vue du compte client", { variant: "success" });
      return res.data;
    }
  } catch (err) {
    enqueueSnackbar("Une erreur est survenue. Veuillez réessayer plus tard.", { variant: "error" });
  }
};

export const resetAccountToSuperAdmin = async () => {
  try {
    const res = await APIAxios<AccountHolders[]>({ ...APIRoutes.resetAccountToSuperAdmin() });
    if (res.data) {
      enqueueSnackbar("Vue du compte client", { variant: "success" });
      return res.data;
    }
  } catch (err) {
    enqueueSnackbar("Une erreur est survenue. Veuillez réessayer plus tard.", { variant: "error" });
  }
};

export const updateAccountHolder = async (companyId: string, name: string) => {
  try {
    const res = await APIAxios({ ...APIRoutes.updateCompanyName(companyId, name) });
    if (res.data) {
      return res.data;
    }
  } catch (err) {
    enqueueSnackbar("Une erreur est survenue. Veuillez réessayer plus tard.", { variant: "error" });
  }
};
