import { paymentStore } from "./payments.store";

export class PaymentServices {
  store = paymentStore;

  resetStore = () => this.store.reset();

  setPaymentIdFromUrl = (paymentId: string) => {
    localStorage.setItem("payment_id", paymentId);
  };

  getPaymentIdFromUrl = (): string | null => {
    return localStorage.getItem("payment_id");
  };

  removePaymentIdFromLocal = () => {
    return localStorage.removeItem("payment_id");
  };
}

export const paymentService = new PaymentServices();
