import { Navigate, Route, Routes } from "react-router";

import { RoutesUnAuth } from "../../utils/routes.utils";
import Register from "./register.screen";
import Layout from "./Layout";
import LoginForm from "./login.screen";
import ForgotPasswordForm from "./forgotPassword.screen";
import ActivateAccount from "./activateAccount.screen";

const UnAuth = () => {
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path={RoutesUnAuth.LOGIN} element={<LoginForm />} />
        <Route path={RoutesUnAuth.LOST_PASSWORD} element={<ForgotPasswordForm />} />
        <Route path={RoutesUnAuth.REGISTER} element={<Register />} />
        <Route path={RoutesUnAuth.ACTIVATE_ACCOUNT} element={<ActivateAccount />} />
        <Route path="*" element={<Navigate to={RoutesUnAuth.LOGIN} replace />} />
      </Route>
    </Routes>
  );
};

export default UnAuth;
